import {
  SET_MAGAZINE_LIST,
  SET_MAGAZINE_FILTER,
  CLEAR_MAGAZINE_FILTER,
  SET_USER_FILTER,
  CLEAR_USER_FILTER,
} from "./actions";

import * as moment from "moment";

const initialState = {
  count: 0,
  categoryFilter: {
    type: "Magazine",
    status: "Published",
  },
  filter: {
    id: null,
    title: "",
    status: "",
    limit: 10,
    skip: 0,
    published_at: {
      from: "",
      to: "",
    },
    utc_offset: moment().utcOffset(),
  },
  common_filter: { text: "", limit: 0, skip: 0 },
  user_filter: { text: "", limit: 10, skip: 0 },
};

const magazineReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAGAZINE_LIST:
      return {
        ...state,
        magazines: action.payload.magazines,
        count: action.payload.count || [],
      };
    case SET_MAGAZINE_FILTER: {
      const { key, value } = action.payload;
      if (key === 'id') {
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value ? parseInt(value) : null,
          },
        };
      }
      if (key === "published_from") {
        return {
          ...state,
          filter: {
            ...state.filter,
            published_at: {
              ...state.filter.published_at,
              from: value,
            },
          },
        };
      }
      if (key === "published_to") {
        return {
          ...state,
          filter: {
            ...state.filter,
            published_at: {
              ...state.filter.published_at,
              to: value,
            },
          },
        };
      }
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case CLEAR_MAGAZINE_FILTER:
      return { ...state, filter: initialState.filter };
    case SET_USER_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        user_filter: { ...state.user_filter, [key]: value },
      };
    }
    case CLEAR_USER_FILTER:
      return { ...state, user_filter: initialState.user_filter };
    default:
      return state;
  }
};

export default magazineReducer;

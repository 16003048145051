import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { useEditInterest } from "./hooks";
import OfferCategoryForm from "../form/category-form";
import { useUser } from "../../../../../common-hooks/user";
import Modals from "../../../../../components/modal";
import { setLanguage } from "../../../../../utils/auth";

const EditCategory = ({ category }) => {
  const {
    editCategoryLoading,
    isOpenModal,
    handleEditCategory,
    toggleModal,
  } = useEditInterest(category);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            setLanguage("en");
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <OfferCategoryForm
              handleInterestForm={handleEditCategory}
              btn="Save"
              fieldValues={category}
              isLoading={editCategoryLoading}
            />
          }
          title="Edit Category"
        />
      ) : null}
    </div>
  );
};

export default EditCategory;

import { RESET_BRANDS_FILTER, SET_BRANDS_FILTER, SET_BRANDS_LIST } from "./actions";


const initialList = {
  brands: [],
  count: 0,
  filter: {
    status: "",
    name: "",
    limit: 10,
    skip: 0,
  },
};

const brandReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_BRANDS_LIST:
      return {
        ...state,
        brands: action.payload.brands,
        count: action.payload.count || 0,
      };

    case SET_BRANDS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_BRANDS_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default brandReducer;

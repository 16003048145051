import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "../../../common-hooks/user";
import { addVoucher, getVouchersList } from "../actions";
import { getVouchersFilter } from "../selectors";

export const useAddVoucher = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [addVoucherLoading, setAddVoucherLoading] = useState(false);
  const filter = useSelector(getVouchersFilter);
  const { id } = useUser();
  const handleAddVoucher = async (values) => {
    const patterLimit = values.pattern.match(/#/g)
      ? values.pattern.match(/#/g).length
      : 0;
    const prefix = `${values.voucher_prefix}${
      values.pattern.split("#")[0]
    }`?.toUpperCase();

    const postfix = `${
      values.pattern.split("#")[values.pattern.split("#").length - 1]
    }${values.voucher_postfix}`?.toUpperCase();

    const payload = {
      title: values.title,
      description: values.description,
      coupon_generation_type: values.consumption_type,
      count: values.consumption_type === "Single" ? 1 : parseInt(values.count),
      purpose: values.purpose,
      event: values.event,
      category: values.category,
      sub_category: values.sub_category,
      entity: values.entity,
      entity_name: values.entity_name,
      discount_type: values.discount_type,
      valid_from: values.valid_from || new Date(),
      valid_till: values.valid_till || new Date("12/31/9999"),
      usage: values.usage,
      usage_count: values.usage_count ? parseInt(values.usage_count) : 1,
      charset: values.voucher_type,
      voucher_prefix: prefix.replace(/\s/g, ""),
      voucher_postfix: postfix.replace(/\s/g, ""),
      infix_digits: patterLimit ? parseInt(patterLimit) : 0,
      created_by: id,
    };

    if (values.discount_type) {
      payload["discount"] = values.discount_amount
        ? parseInt(values.discount_amount)
        : values.discount_credits
        ? parseInt(values.discount_credits)
        : parseInt(values.max_discount);
      payload["max_discount"] = values.max_discount
        ? parseInt(values.max_discount)
        : values.discount_amount
        ? parseInt(values.discount_amount)
        : parseInt(values.discount_credits);
    }

    setAddVoucherLoading(true);
    const is_success = await dispatch(addVoucher(payload));
    setAddVoucherLoading(false);
    if (is_success) {
      dispatch(getVouchersList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addVoucherLoading,
    handleAddVoucher,
    isOpenModal,
    toggleModal,
  };
};

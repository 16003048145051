export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Status",
    accessor: "status",
    width: 60,
    disableGlobalFilter: true,
  },
  {
    Header: "Action",
    accessor: "action",
    width: 60,
  },
];

export const testimonialStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const fieldsName = {
  status: "status",
  description: "description",
  desktop_banner: "desktop_banner",
  mobile_banner: "mobile_banner",
  icon: "icon",
  type: "type",
  stars: "stars",
  user_name:"user_name"
};

export const testimonialType =[
  {
    id: 1,
    value:'Others',
    displayText:'Others'
  },
  {
    id: 2,
    value: 'Play Store',
    displayText:'Play Store'
  },
  {
    id: 3,
    value: 'App Store',
    displayText:'App Store'
  },
]

export const testimonialStars = [
  {
    id: 1,
    value:1,
    displayText:1
  },
  {
    id: 2,
    value:2,
    displayText:2
  },
  {
    id: 3,
    value:3,
    displayText:3
  },
  {
    id: 4,
    value:4,
    displayText:4
  },
  {
    id: 5,
    value:5,
    displayText:5
  },
]
export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 60,
  },
  {
    Header: "Action",
    accessor: "action",
    disableGlobalFilter: true,
    width: 30,
  },
];

export const fieldNames = {
  title: "title",
  status: "status",
  label_id: "label_id",
  fileBanner: "fileBanner",
  fileBannerTile: "fileBannerTile",
  published_at: "published_at",
  redirection_link: "redirection_link"
};

export const statusOptions = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "In Active",
    displayText: "In Active",
  },
];





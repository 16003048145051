import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMagazineData,
  editMagazine,
  setMagazineFilter,
  getMagazineList,
  clearMagazineFilter,
} from "../actions";
import { clearFiles, setFiles } from "../../../../utils/upload-file/actions";
import {
  getCommonFilter,
  getMagazineFilter,
} from "../selectors";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export const useEditMagazine = ({
  match: {
    params: { magazine_id },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editMagazineLoading, setEditMagazineLoading] = useState(false);
  const cover_page = useSelector(({ fileReducer }) => fileReducer.files);
  const [selectedMagazine, setSelectedMagazine] = useState();
  const filters = useSelector(getCommonFilter);
  const magazineFilter = useSelector(getMagazineFilter);
  const [magazines, setMagazines] = useState([]);

  useEffect(() => {
    const getRequiredData = async () => {
        setEditMagazineLoading(true);
      dispatch(clearFiles());
      const { magazine, cover_page } = await dispatch(
        getMagazineData(magazine_id)
      );

      if (magazine) {
        magazine.title = magazine.title || "";
        setSelectedMagazine(magazine);
      }
      if(cover_page){
        dispatch(setFiles(cover_page));
      }
      setEditMagazineLoading(false);
    };
    getRequiredData();
  }, [magazine_id, dispatch, filters]);


  const handleEditMagazine = async (values) => {
    try {
     setEditMagazineLoading(true);
     const payload = {
        magazine: {
          title: values.title,
          status: values.status,
          published_at: values.published_at,
          redirection_link: values.redirection_link,
        },
        cover_page: cover_page.length > 0 ? cover_page[0] : null,
      };
      const is_success = await dispatch(editMagazine(payload, magazine_id));

      setEditMagazineLoading(false);
      if (is_success) {
        dispatch(clearFiles());
        dispatch(clearMagazineFilter());
        history.push("/magazines");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setSearchText = useCallback(
    _.debounce((key, value) => {
      dispatch(setMagazineFilter({ key, value }));
    }, 600),
    []
  );

  useEffect(() => {
    (async () => {
      const magazineList = await dispatch(getMagazineList(magazineFilter));
      if (magazineList) {
        await setMagazines(magazineList);
      }
    })();
  }, [dispatch, magazineFilter]);

  return {
    editMagazineLoading,
    handleEditMagazine,
    magazines,
    selectedMagazine,
    setSearchText,
  };
};

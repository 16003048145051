import { useState } from "react";
import { useDispatch } from "react-redux";
import { addOfferCategory } from "../actions";

export const useAddCategory = () => {
  const dispatch = useDispatch();

  const [addCategoryLoading, setAddCategoryLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleAddCategory = async (values) => {
    try {
      const payload = {
        interest: {
          title: values.title,
          status: values.status,
        },
      };
      setAddCategoryLoading(true);
      const is_success = await dispatch(addOfferCategory(payload));
      setAddCategoryLoading(false);
      if (is_success) {
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    addCategoryLoading,
    isOpenModal,
    handleAddCategory,
    toggleModal,
  };
};

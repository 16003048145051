import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getMagazineList,
  setMagazineFilter,
  deleteMagazineData,
  clearMagazineFilter,
} from "./actions";
import { getMagazines, getMagazineCount, getMagazineFilter } from "./selectors";


export const useMagazines = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const magazines = useSelector(getMagazines);
  const filters = useSelector(getMagazineFilter);
  const magazineCount = useSelector(getMagazineCount);
  const [loadingMagazines, setLoadingMagazines] = useState(false);

  useEffect(() => {
    dispatch(clearMagazineFilter());
  }, [dispatch]);

  useEffect(() => {
    const fetchMagazines = async () => {
      setLoadingMagazines(true);
      await dispatch(getMagazineList(filters));
      setLoadingMagazines(false);
    };
    fetchMagazines();
  }, [filters, dispatch]);

  const deleteMagazine = async (magazine_id) => {
    setLoadingMagazines(true);
    await dispatch(deleteMagazineData(magazine_id));
    setLoadingMagazines(false);
  };

  const handleEditClick = (magazine_id) => {
    history.push({ pathname: `/edit-magazine/${magazine_id}` });
  };

  const handleAddClick = () => {
    history.push("/add-magazine");
  };

  const setFilter = (key, value) => {
    dispatch(setMagazineFilter({ key, value }));
  };
  const resetFilter = () => {
    dispatch(clearMagazineFilter());
  };

  return {
    setFilter,
    loadingMagazines,
    filters,
    magazines,
    magazineCount,
    deleteMagazine,
    handleEditClick,
    handleAddClick,
    resetFilter,
  };
};

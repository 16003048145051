import React from "react";
import { Formik, ErrorMessage } from "formik";

import { TextField, MenuItem, Chip, Tooltip, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataLoader } from "../../../../../components/loader";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import { offerFormSchema } from "./validation-schema";
import { fieldsName, offerType, offerStatus, featuredOffer } from "../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { fileContext, imageDimensions } from "../../../../../constants";
import { useOfferForm } from "./hooks.js";
import TextfieldChips from "../../../../../components/text-field-chips/index.js";
import ProgressChip from "../../../../../components/progress-chip/index.js";
import Croppier from "../../../../../components/image-croppier/index.js";
import DateTimePicker from "../../../../../components/date-time-picker/index.js";

const OfferForm = (props) => {
  const { product_banner } = imageDimensions;
  const {
    handleProductBannerFile,
    handleCropping,
    uploadError,
    handleDeleteAFile,
    files,
    isFileUploadData,
    uploadProgress,
  } = useUploadFiles();

  const {
    currentOfferBanner,
    handleOfferForm,
    btn,
    isLoading,
    formValues,
    products,
    marketplaces,
    handleDataUpdate,
    handleFormUpdate,
    setCurrentOfferBanner,
  } = useOfferForm(props);
  console.log(formValues);
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();
  const {
    title,
    type,
    subTitle,
    code,
    validity,
    productId,
    details,
    note,
    redirectLink,
    status,
    banner,
    marketplaceId,
    is_featured,
  } = fieldsName;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={offerFormSchema}
      initialValues={{
        title: formValues?.title || "",
        subTitle: formValues?.subTitle || formValues?.sub_title || "",
        code: formValues?.code || "",
        validity:
          (formValues?.validity && new Date(formValues.validity)) || new Date(),
        details: formValues?.details || [],
        note: formValues.note || "",
        redirectLink:
          formValues.redirectLink || formValues.redirection_link || "",
        type: formValues?.type || offerType[0].value,
        productId:
          (formValues?.productId &&
            products.find(
              (product) => product.id === formValues?.productId.id
            )) ||
          (formValues?.product?.id &&
            products.find(
              (product) => product.id === formValues?.product.id
            )) ||
          "",
        banner:
          files?.filter(
            (file) => file?.file_context === fileContext.PRODUCT_BANNER
          ) || "",
        status: formValues?.status || "",
        marketplaceId:
          (formValues?.marketplaceId &&
            marketplaces.find(
              (data) => data.id === formValues?.marketplaceId.id
            )) ||
          (formValues?.marketplace_id &&
            marketplaces.find(
              (data) => data.id === formValues?.marketplace_id
            )) ||
          "",
        is_featured: formValues?.is_featured || false,
      }}
      onSubmit={handleOfferForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title}
                  onChange={(event) => {
                    handleFormUpdate(event);
                    handleFormValueChange(rest, title);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={subTitle}
                  placeholder="Sub Title"
                  label="Sub Title"
                  value={rest.values.subTitle}
                  onChange={(event) => {
                    handleFormUpdate(event);
                    handleFormValueChange(rest, subTitle);
                  }}
                  onFocus={() => handleFocusField(subTitle)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === subTitle ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={subTitle}
                  />
                )}
              </div>
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormUpdate(event);
                  handleFormValueChange(rest, status);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {offerStatus.map((offer) => (
                  <MenuItem
                    key={offer.id}
                    className="material-form__option"
                    value={offer.value}
                  >
                    {offer.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <div>
              <Autocomplete
                options={products}
                getOptionLabel={(product) => (product.name ? product.name : "")}
                value={rest.values.productId}
                name={productId}
                onChange={(event, value) => {
                  setFieldValue(productId, value);
                  handleDataUpdate(productId, value);
                }}
                onFocus={() => handleFocusField(productId)}
                onBlur={() => handleBlurField()}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      "& > img": {
                        mr: 3,
                        flexShrink: 0,
                        width: 20,
                        height: "auto",
                      },
                    }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width={20}
                      style={{ marginRight: 10 }}
                      src={props?.icon?.url}
                      alt="product icon"
                    />
                    {props.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={productId}
                    placeholder="Select Product"
                    label="Select Product"
                    variant="standard"
                  />
                )}
              />
              {focusField === productId ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={productId}
                />
              )}
            </div>
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={type}
                  placeholder="Offer Type"
                  label="Offer Type"
                  select
                  value={rest.values.type}
                  onChange={(event) => {
                    handleFormUpdate(event);
                    handleFormValueChange(rest, type);
                  }}
                  onFocus={() => handleFocusField(type)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.type}
                >
                  {offerType.map((type) => (
                    <MenuItem
                      key={type.id}
                      className="material-form__option"
                      value={type.value}
                    >
                      {type.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === type ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={type}
                  />
                )}
              </div>
              <div className="materila-form_inline-child">
                <Autocomplete
                  options={marketplaces}
                  getOptionLabel={(data) => (data.name ? data.name : "")}
                  value={rest.values.marketplaceId}
                  name={marketplaceId}
                  onChange={(event, value) => {
                    setFieldValue(marketplaceId, value);
                    handleDataUpdate(marketplaceId, value);
                  }}
                  onFocus={() => handleFocusField(marketplaceId)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={marketplaceId}
                      placeholder="Select Marketplace"
                      label="Select Marketplace"
                      variant="standard"
                    />
                  )}
                />
                {focusField === marketplaceId ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={marketplaceId}
                  />
                )}
              </div>
            </div>
            {rest.values.type === offerType[0].value && (
              <div className="material-form_inline-fields d-flex align-items-center">
                <div className="materila-form_inline-child">
                  <TextField
                    className="material-form__field"
                    name={code}
                    placeholder="Code"
                    label="Code"
                    type="text"
                    value={rest.values.code}
                    onChange={(event) => {
                      handleFormUpdate(event);
                      handleFormValueChange(rest, code);
                    }}
                    onFocus={() => handleFocusField(code)}
                    onBlur={() => handleBlurField()}
                  />
                  {focusField === code ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={code}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={is_featured}
                placeholder="Featured"
                label="Featured"
                select
                value={rest.values.is_featured}
                onChange={(event) => {
                  handleFormUpdate(event);
                  handleFormValueChange(rest, is_featured);
                }}
                onFocus={() => handleFocusField(is_featured)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.is_featured}
              >
                {featuredOffer.map((featured) => (
                  <MenuItem
                    key={featured.id}
                    className="material-form__option"
                    value={featured.value}
                  >
                    {featured.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === is_featured ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={is_featured}
                />
              )}
            </div>
            {rest?.values?.is_featured && (
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <p style={{ fontSize: 11, marginBottom: 5 }}>
                      Featured Card (Preffered Size is 375px * 200px)
                    </p>
                    <label htmlFor="banner">Choose Banner</label>
                    <span>
                      {files
                        ?.filter(
                          (file) =>
                            file?.file_context === fileContext.PRODUCT_BANNER
                        )
                        .map((x) => {
                          return (
                            <Chip
                              className="selected-file"
                              key={x.uuid_file_name}
                              label={x.original_file_name}
                              onDelete={() => handleDeleteAFile(x)}
                              variant="outlined"
                            />
                          );
                        })}

                      {isFileUploadData
                        .filter(
                          (file) =>
                            file.fileContext === fileContext.PRODUCT_BANNER
                        )
                        .map((x, i) => {
                          return (
                            <>
                              {x.isUpload && (
                                <ProgressChip
                                  fileName={x.fileName}
                                  uploadProgress={uploadProgress}
                                />
                              )}
                            </>
                          );
                        })}
                    </span>
                    <input
                      accept="image/*"
                      onBlur={rest.handleBlur}
                      type="file"
                      name={banner}
                      id="banner"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handleCropping(
                          event.currentTarget.files,
                          setFieldValue,
                          banner,
                          fileContext.PRODUCT_BANNER,
                          handleProductBannerFile,
                          product_banner,
                          setCurrentOfferBanner
                        );
                        // handleProductBannerFile(event.currentTarget.files[0]);
                        // setFieldValue({ fileBanner }, files);
                      }}
                    />
                  </div>
                  {uploadError ? (
                    <div className="material-form__error">{uploadError}</div>
                  ) : (
                    <ErrorMessage
                      render={(msg) => (
                        <div
                          style={{ marginTop: 0 }}
                          className="material-form__error"
                        >
                          {msg}
                        </div>
                      )}
                      name={banner}
                    />
                  )}
                </div>
              </div>
            )}
            {currentOfferBanner?.length > 0 &&
              currentOfferBanner?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={banner}
                    context={fileContext.PRODUCT_BANNER}
                    dimensions={{
                      width: 375,
                      height: 200,
                    }}
                    handleUploadFile={handleProductBannerFile}
                    setCurrentBanner={setCurrentOfferBanner}
                    index={i}
                  />
                </div>
              ))}
            <div>
              <TextfieldChips
                initialChips={rest.values.details}
                name="Details"
                onDataChange={(data) => {
                  setFieldValue(details, data);
                  handleDataUpdate(details, data);
                }}
              />
              {focusField === details ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={details}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <DateTimePicker
                selectedDate={rest.values.validity}
                handleChange={(value) => {
                  setFieldValue(validity, value);
                  handleDataUpdate(validity, value);
                }}
                id="validity"
                name={validity}
                label="Validity"
                customInput={
                  <TextField
                    className="material-form__field"
                    id="validity"
                    label="Validity"
                    autoComplete="off"
                  />
                }
              />
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={redirectLink}
                  placeholder="Redirect URL"
                  label="Redirect URL"
                  type="text"
                  value={rest.values.redirectLink}
                  onChange={(event) => {
                    handleFormUpdate(event);
                    handleFormValueChange(rest, redirectLink);
                  }}
                  onFocus={() => handleFocusField(redirectLink)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === redirectLink ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={redirectLink}
                  />
                )}
              </div>
            </div>

            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={note}
                  placeholder="Note"
                  label="Note"
                  type="text"
                  value={rest.values.note}
                  onChange={(event) => {
                    handleFormUpdate(event);
                    handleFormValueChange(rest, note);
                  }}
                  onFocus={() => handleFocusField(note)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === redirectLink ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={note}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success btn-right btn-sm interest__marginTop30"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default OfferForm;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategorieList, getOffersFilter } from "../selectors";
import { clearLanguage, getLanguage } from "../../../../../utils/auth";
import { clearFiles, setFiles } from "../../../../../utils/upload-file/actions";
import {
  editOffer,
  editProduct,
  getCategoryList,
  getOffer,
  getOffersList,
  getProduct,
} from "../actions";
import { initialLanguageFieldValue } from "../../../../../constants";
import { getProducts } from "../../product/selectors";
import { getProductsList } from "../../product/actions";
import { productsListParams } from "../constant";
import moment from "moment";
import { getMarketPlacesList } from "../../marketplace/actions";
import { getMarketPlaces } from "../../marketplace/selectors";

export const useEditOffer = ({ id }) => {
  const dispatch = useDispatch();
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const products = useSelector(getProducts);
  const offerFilter = useSelector(getOffersFilter);
  const marketplaces = useSelector(getMarketPlaces);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editOfferLoading, setEditOfferLoading] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState();

  const getOfferFieldData = async () => {
    dispatch(clearFiles());
    setEditOfferLoading(true);
    const data = await dispatch(getOffer(id));
    const is_success = await dispatch(getProductsList(productsListParams));
    await dispatch(getMarketPlacesList(productsListParams));
    const { files } = data;
    if (data && is_success) {
      files.forEach((file) => dispatch(setFiles(file)));
      setSelectedOffer(data.offer);
      setEditOfferLoading(false);
      toggleModal();
    }
  };

  const handleEditOffer = async (values) => {
    try {
      const payload = {
        offer: {
          title: values.title,
          sub_title: values.subTitle,
          details: values.details,
          note: values.note,
          type: values.type,
          code: values.code,
          redirection_link: values.redirectLink,
          validity: moment(values.validity).toDate().toString(),
          status: values.status,
          marketplace_id: values.marketplaceId.id,
          is_featured: values.is_featured,
          product_id: values.productId.id,
        },
        ...(files[0] && { banner: files[0] }),
      };
      setEditOfferLoading(true);
      const is_success = await dispatch(editOffer(payload, id));
      setEditOfferLoading(false);
      if (is_success) {
        dispatch(getOffersList(offerFilter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editOfferLoading,
    isOpenModal,
    selectedOffer,
    products,
    marketplaces,
    getOfferFieldData,
    handleEditOffer,
    toggleModal,
  };
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";
import {
  getPartnersOfferList,
  getPlan,
  getPlanList,
  updatPlanOffers,
} from "../actions";
import {
  getPlansFilter,
  getPartnerOffer,
  getPartnersOfferFilter,
} from "../selectors";

export const useManageOffers = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [editManangeOffersLoading, setEditManangeOffersLoading] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const filter = useSelector(getPlansFilter);
  const partnerOffer = useSelector(getPartnerOffer);

  const [isDisable, setIsDisable] = useState(false);

  const partnerOfferFilter = useSelector(getPartnersOfferFilter);

  const getPlanFieldData = async () => {
    const selectedLanguage = getLanguage();
    setEditManangeOffersLoading(true);
    const data = await dispatch(getPlan(id));
    const partner_offers = await dispatch(
      getPartnersOfferList(partnerOfferFilter)
    );

    if (data.title && data.description) setIsDisable(true);

    if (data && partner_offers.length) {
      data.plan_variants = data.plan_variants.map((item) => {
        const { partner_offer_ids } = item;
        return {
          ...item,
          partner_offer_ids: partner_offers.filter((offer) => {
            return partner_offer_ids.find((id) => offer.id === id);
          }),
        };
      });
      data.partner_offer_text = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.partner_offer_text || "",
      };
      data.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.title || "",
      };
      data.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.description || "",
      };
      setSelectedPlan(data);
      toggleModal();
    }
    setEditManangeOffersLoading(false);
  };

  const handleLangugeData = async (rest, setFieldValue) => {
    setIsDisable(false);
    const selectedLanguage = getLanguage();
    const data = await dispatch(getPlan(id));
    if (data.title && data.description) setIsDisable(true);

    if (data) {
      data.language = selectedLanguage;

      data.partner_offer_text = {
        ...rest.values.partner_offer_text,
        [selectedLanguage]:
          rest.values.partner_offer_text[selectedLanguage] ||
          data?.partner_offer_text ||
          "",
      };

      setFieldValue("partner_offer_text", data.partner_offer_text);

      data.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || data?.title || "",
      };
      setFieldValue("title", data.title);

      data.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] || data?.description || "",
      };

      setFieldValue("description", data.description);
      setSelectedPlan(data);
    }
  };

  const handleManageOffer = async (values) => {
    const { variants } = values;

    const variantData = [];
    variants.forEach((v) => {
      variantData.push({
        no_of_credits: v.no_of_credits,
        duration: v.duration,
        gross_amount: v.gross_amount,
        discount_type: v.discount_type,
        discount: v.discount,
        amount: v.amount,
        partner_offer_ids: partnerOffer.filter((offer) =>
          v.partner_offer_ids.find((id) => id === offer.id)
        ),
      });
    });

    const payload = {
      plan: {
        partner_offer_text: values.partner_offer_text,
        title: values.title,
        description: values.description,
      },
      plan_variants: variants.map((val) => ({
        id: val.id,
        partner_offer_ids: val.partner_offer_ids.map((offer) => offer.id),
      })),
    };

    setEditManangeOffersLoading(true);
    const is_success = await dispatch(updatPlanOffers(id, payload));
    setEditManangeOffersLoading(false);
    if (is_success) {
      dispatch(getPlanList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editManangeOffersLoading,
    handleManageOffer,
    isOpenModal,
    toggleModal,
    getPlanFieldData,
    selectedPlan,
    handleLangugeData,
    isDisable,
  };
};

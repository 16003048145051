import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import { getBrandFilter } from "../selectors";
import { editBrand, getBrandsList, getSingleBrand } from "../actions";
import { fileContext } from "../../../../../constants";

export const useEditBrand = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(getBrandFilter);
  const [editBrandLoading, setEditBrandLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const getBrand = async () => {
    setEditBrandLoading(true);
    dispatch(clearFiles());
    const brand = await dispatch(getSingleBrand(id));
    if (brand) {
      brand.brand_name = brand.name;
      setSelectedBrand(brand);
    }
    toggleModal();
    setEditBrandLoading(false);
  };

  const handleEditBrand = async (values) => {
    const payload = {
      brand: {
        name: values.brand_name,
        status: values.status,
      },
      logo: files.find((file) => file.file_context === fileContext.BRAND_LOGO),
    };

    setEditBrandLoading(true);
    const is_success = await dispatch(editBrand(payload, id));
    setEditBrandLoading(false);
    if (is_success) {
      setEditBrandLoading(false);
      dispatch(getBrandsList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    isOpenModal,
    selectedBrand,
    editBrandLoading,
    getBrand,
    handleEditBrand,
    toggleModal,
  };
};

import React from "react";
import DataTable from "../../../../components/table";
import { columns } from "./constant";
import { useMarketplaces } from "./hooks";

import AddMarketplace from "./add-marketplace";
import EditMarketplace from "./edit-marketplace";

function Marketplaces() {
  const {
    marketPlaces,
    loadingMarketPlaces,
    filters,
    marketPlacesCount,
    setFilter,
  } = useMarketplaces();

  const configureTableData = () => {
    try {
      let results = marketPlaces.map((marketplace) => {
        let newMarketplace = Object.assign({}, marketplace);

        newMarketplace["action"] = (
          <EditMarketplace marketplace={marketplace} />
        );

        return newMarketplace;
      });

      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Marketplaces"
      addBtn={<AddMarketplace />}
      CreateTableData={configureTableData}
      handleSetFilter={setFilter}
      count={marketPlacesCount}
      skip={filters?.skip}
      isLoading={loadingMarketPlaces}
    />
  );
}

export default Marketplaces;

import { useState } from "react";
import { initialLanguageFieldValue } from "../../../../../constants";

export const useOfferForm = ({
  fieldValues,
  handleOfferForm,
  btn,
  isLoading,
  products,
  marketplaces,
}) => {
  const formField = {
    title: "",
    subTitle: "",
    code: "",
    validity: "",
    details: [],
    note: "",
    redirectLink: "",
    type: "",
    productId: "",
    status: "",
    marketplaceId: "",
    is_featured: false,
  };
  const [currentOfferBanner, setCurrentOfferBanner] = useState([]);
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleDataUpdate = (name, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };
  return {
    currentOfferBanner,
    handleOfferForm,
    btn,
    isLoading,
    formValues,
    products,
    marketplaces,
    handleDataUpdate,
    handleFormUpdate,
    setCurrentOfferBanner,
  };
};

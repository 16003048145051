import {
    SET_BLOG_LIST,
    SET_BLOG_FILTER,
    CLEAR_BLOG_FILTER,
    SET_USER_FILTER,
    CLEAR_USER_FILTER,
  } from "./actions";
  
  import * as moment from "moment";
  
  const initialState = {
    count: 0,
    categoryFilter: {
      type: "Blog",
      status: "Published",
    },
    filter: {
      id: null,
      title: "",
      status: "",
      limit: 10,
      skip: 0,
      published_at: {
        from: "",
        to: "",
      },
      utc_offset: moment().utcOffset(),
    },
    common_filter: { text: "", limit: 0, skip: 0 },
  };
  
  const seniorTodayBlogReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_BLOG_LIST:
        return {
          ...state,
          blogs: action.payload.blogs,
          count: action.payload.count || [],
        };
      case SET_BLOG_FILTER: {
        const { key, value } = action.payload;
        if (key === 'id') {
          return {
            ...state,
            filter: {
              ...state.filter,
              [key]: value ? parseInt(value) : null,
            },
          };
        }
        if (key === "published_from") {
          return {
            ...state,
            filter: {
              ...state.filter,
              published_at: {
                ...state.filter.published_at,
                from: value,
              },
            },
          };
        }
        if (key === "published_to") {
          return {
            ...state,
            filter: {
              ...state.filter,
              published_at: {
                ...state.filter.published_at,
                to: value,
              },
            },
          };
        }
        return {
          ...state,
          filter: {
            ...state.filter,
            [key]: value,
          },
        };
      }
      case CLEAR_BLOG_FILTER:
        return { ...state, filter: initialState.filter };
      case SET_USER_FILTER: {
        const { key, value } = action.payload;
        return {
          ...state,
          user_filter: { ...state.user_filter, [key]: value },
        };
      }
      case CLEAR_USER_FILTER:
        return { ...state, user_filter: initialState.user_filter };
      default:
        return state;
    }
  };
  
  export default seniorTodayBlogReducer;
  
import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/services/offers";

export const SET_OFFER_CATEGORY_LIST = `${namespace}/SET_OFFER_CATEGORY_LIST`;
export const SET_OFFER_CATEGORY_FILTER = `${namespace}/SET_OFFER_CATEGORY_FILTER`;

const setOfferCategoryList = (payload) => ({
  type: SET_OFFER_CATEGORY_LIST,
  payload,
});

export const setOfferCategoryFilter = (payload) => ({
  type: SET_OFFER_CATEGORY_FILTER,
  payload,
});

export const getOfferCategoryList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getInterests, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setOfferCategoryList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addOfferCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createInterests, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(getOfferCategoryList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Category not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editOfferCategory = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateInterests(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(getOfferCategoryList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Category not edited, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteOfferCategory = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteInterests(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(getOfferCategoryList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

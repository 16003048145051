import * as Yup from "yup";

export const testimonialFormSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  type: Yup.string().required("Type is required"),
  description: Yup.string().required("Description is required"),
  stars: Yup.string(),
  user_name: Yup.string(),
  desktop_banner: Yup.array(),
  mobile_banner: Yup.array(),
  icon: Yup.array()
});

export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Description",
    accessor: "description",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 70,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
    width: 70,
  },
];

export const categoryStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const categoryType = [
  {
    id: 1,
    value: "Blog",
    displayText: "Blog",
  },
  {
    id: 2,
    value: "Event",
    displayText: "Event",
  },
  {
    id: 3,
    value: "Faq",
    displayText: "Faq",
  },
  {
    id: 4,
    value: "Video",
    displayText: "Video",
  },
  {
    id: 5,
    value: "Product",
    displayText: "Product",
  },
];

export const fieldsName = {
  title: "title",
  description: "description",
  type: "type",
  status: "status",
  interestIds: "interestIds",
  file: "file",
  language: "language",
  is_popular: "is_popular",
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategorieList, getProductFilter } from "../selectors";
import { clearLanguage } from "../../../../../utils/auth";
import { clearFiles, setFiles } from "../../../../../utils/upload-file/actions";
import {
  editProduct,
  getCategoryList,
  getProduct,
  getProductsList,
} from "../actions";
import { fileContext } from "../../../../../constants";
import { brandsListParams, categoryParams } from "../constant";
import { getBrandsList } from "../../brand/actions";
import { getBrands } from "../../brand/selectors";

export const useEditInterest = ({ id }) => {
  const dispatch = useDispatch();
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categories = useSelector(getCategorieList);
  const brands = useSelector(getBrands);
  const productFilter = useSelector(getProductFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editProductLoading, setEditProductLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const getProductFieldData = async () => {
    dispatch(clearFiles());
    setEditProductLoading(true);
    const data = await dispatch(getProduct(id));
    const is_success = await dispatch(getCategoryList(categoryParams));
    const { files } = data;
    if (data && is_success) {
      await dispatch(getBrandsList(brandsListParams));
      files.forEach((file) => {
        if (file) dispatch(setFiles(file));
      });
      setSelectedProduct(data.product);
      setEditProductLoading(false);
      toggleModal();
    }
  };

  const handleEditProduct = async (values) => {
    try {
      const payload = {
        product: {
          name: values.title,
          status: values.status,
          category_id: values.categoryId.id,
          brand_id: values.brandId.id,
          about: values.about,
        },
        icon: files.find(
          (file) => file.file_context === fileContext.PRODUCT_ICON
        ),
        banner: files.find(
          (file) => file.file_context === fileContext.PRODUCT_BANNER
        ),
      };
      setEditProductLoading(true);
      const is_success = await dispatch(editProduct(payload, id));
      setEditProductLoading(false);
      if (is_success) {
        dispatch(getProductsList(productFilter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editProductLoading,
    isOpenModal,
    selectedProduct,
    categories,
    brands,
    getProductFieldData,
    handleEditProduct,
    toggleModal,
  };
};

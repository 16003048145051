import React from "react";
import Modals from "../../../../components/modal";
import DeleteConfirmation from "../../../../components/delete-confirmation";
import { useDeleteMagazine } from "./hooks";
import ActionButton from "../../../../components/action-button";

const DeleteMagazine = ({ magazine }) => {
  const { handleDeleteMagazine, isOpenModal, toggleModal, deleteMagazineLoading } =
  useDeleteMagazine(magazine);

  return (
    <>
      <ActionButton
        title="Delete"
        className="pull-right"
        onClick={toggleModal}
        iconClassName={`fa fa-trash icon-btn-action text-danger `}
      />

      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <DeleteConfirmation
              message="Are you sure you want to delete?"
              handleDelete={handleDeleteMagazine}
              isLoading={deleteMagazineLoading}
            />
          }
          title="Delete Magazine"
        />
      ) : null}
    </>
  );
};

export default DeleteMagazine;

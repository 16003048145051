import { api, apiEndPoints } from "../../../api";
import { showSnackbar } from "../../../components/snackbar/actions";
import { snackbarTypes } from "../../../constants";
import { clearFiles } from "../../../utils/upload-file/actions";

const namespace = "containers/magazines";

export const SET_MAGAZINE_LIST = `${namespace}/SET_MAGAZINE_LIST`;
export const SET_MAGAZINE_FILTER = `${namespace}/SET_MAGAZINE_FILTER`;
export const CLEAR_MAGAZINE_FILTER = `${namespace}/CLEAR_MAGAZINE_FILTER`;
export const SET_USER_FILTER = `${namespace}/SET_USER_FILTER`;
export const CLEAR_USER_FILTER = `${namespace}/CLEAR_USER_FILTER`;

const setMagazineList = (payload) => ({
  type: SET_MAGAZINE_LIST,
  payload,
});

export const setMagazineFilter = (payload) => ({
  type: SET_MAGAZINE_FILTER,
  payload,
});

export const clearMagazineFilter = (payload) => ({
  type: CLEAR_MAGAZINE_FILTER,
  payload,
});

export const setUserFilter = (payload) => ({
  type: SET_USER_FILTER,
  payload,
});

export const clearUserFilter = (payload) => ({
  type: CLEAR_USER_FILTER,
  payload,
});


export const createMagazine = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createMagazines, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getMagazineList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getMagazines, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    
    if (is_success) {
      dispatch(setMagazineList(data));
      return data?.magazines;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getMagazineData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getMagazine(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editMagazine = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateMagazine(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );

      dispatch(clearFiles());
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteMagazineData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteMagazine(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};




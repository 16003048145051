import React from "react";
import { Button } from "reactstrap";

import { useAddOffer } from "./hooks";
import Modals from "../../../../../components/modal";
import { useUser } from "../../../../../common-hooks/user";
import OfferForm from "../form/offer-form";

const AddOffer = (props) => {
  const {
    addOfferLoading,
    isOpenModal,
    products,
    marketplaces,
    toggleModal,
    getProductsData,
    handleAddOffer,
  } = useAddOffer(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          getProductsData();
        }}
      >
        <i className="fa fa-plus"></i> Add Offer
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <OfferForm
              handleOfferForm={handleAddOffer}
              btn="Add"
              isLoading={addOfferLoading}
              products={products}
              marketplaces={marketplaces}
            />
          }
          title="Add Offer"
        />
      ) : null}
    </div>
  );
};

export default AddOffer;

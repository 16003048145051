import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Blogs from '../../../CMS/blog/senior-today-blogs';
import AddBlog from '../../../CMS/blog/senior-today-blogs/add-blog';
import EditBlog from '../../../CMS/blog/senior-today-blogs/edit-blog';


export default () => (
  <Switch>
    <Route path="/senior-today-blogs" component={Blogs} />
    <Route path="/add-senior-today-blog" component={AddBlog} />
    <Route path="/edit-senior-today-blog/:blog_id" component={EditBlog} />
  </Switch>
);

import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSeniorTodayBlog,
  getBlogList,
  setBlogFilter,
  clearBlogFilter,
  getLabelList
} from "../actions";
import _ from "lodash";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import {
  getCommonFilter,
  getBlogFilter,
} from "../selectors";
import { useHistory } from "react-router-dom";

export const useAddBlog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addBlogLoading, setAddBlogLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const banner = useSelector(({ fileReducer }) => fileReducer.files);
  const [blogs, setBlogs] = useState([]);
  const filters = useSelector(getCommonFilter);
  const blogFilter = useSelector(getBlogFilter);

  useEffect(() => {
    const getRequiredData = async () => {
      setAddBlogLoading(true);
      dispatch(clearFiles());
      const { labels } = await dispatch(getLabelList(filters));
      if (labels) {
        setLabels(labels);
      }
      setAddBlogLoading(false);
    };
    getRequiredData();
  }, [dispatch, filters]);

  useEffect(() => {
    (async () => {
      const blogs = await dispatch(getBlogList(blogFilter));
      if (blogs) {
        setBlogs(blogs);
      }
    })();
  }, [dispatch, blogFilter]);

  const handleAddBlog = async (values) => {
    try {
      setAddBlogLoading(true);
      const payload = {
        blog: {
          title: values.title,
          label_id: values.label_id.id,
          status: values.status,
          published_at: values.published_at,
          redirection_link: values.redirection_link,
        },
        banner: banner.length > 0 ? banner[0] : null,
      };
      const is_success = await dispatch(createSeniorTodayBlog(payload));
      setAddBlogLoading(false);
      if (is_success) {
        dispatch(clearFiles());
        dispatch(clearBlogFilter());
        history.push("/senior-today-blogs");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setSearchText = useCallback(
    _.debounce((key, value) => {
      dispatch(setBlogFilter({ key, value }));
    }, 600),
    []
  );

  return {
    addBlogLoading,
    handleAddBlog,
    setSearchText,
    labels,
    blogs,
  };
};

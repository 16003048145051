import React from "react";
import DataTable from "../../components/data-table";
import { columns, cardsConstant, paymentStatus } from "./constant";
import DashboardCard from "../../components/dashboard-card";
import { useFinanceDashboard } from "./hooks";
import Tooltip from "@material-ui/core/Tooltip";
import * as moment from "moment";
import SearchFilter from "../../components/filters/search-filter";
import SelectFilter from "../../components/filters/select-filter";
import DateRangeFilter from "../../components/filters/date-range-filter";

const Dashboard = () => {
  const {
    handleChangeCard,
    paymentHistories,
    count,
    stats: { this_month, this_quarter, this_year, today },
    setFilter,
    resetFilter,
    loadingPaymenthistories,
    paymentHistoryFilter,
    downloadPaymentHistories,
    downloadUserInvoice,
  } = useFinanceDashboard();

  const { THIS_MONTH, THIS_QUARTER, THIS_YEAR, TODAY } = cardsConstant;

  const configureTableData = () => {
    try {
      let results = paymentHistories.map((transaction) => {
        let newTransaction = Object.assign({}, transaction);
        newTransaction["created_at"] = moment(transaction.created_at).format(
          "DD-MM-YYYY @ hh:mm A"
        );
        newTransaction["completed_at"] = transaction.completed_at
          ? moment(transaction.completed_at).format("DD-MM-YYYY @ hh:mm A")
          : "NA";
        newTransaction["user_id"] = transaction?.user?.id || "NA";
        newTransaction["user_name"] = transaction?.user?.name || "NA";
        newTransaction["gateway"] = transaction?.gateway || "NA";

        newTransaction["amount"] = transaction.amount.toFixed(2);
        newTransaction["gst"] = transaction.gst.toFixed(2);
        newTransaction["gross_amount"] = transaction.gross_amount.toFixed(2);
        newTransaction["plan_details"] =
          transaction.plan_variant.plan.code === "TopUp"
            ? `${transaction.plan_variant.no_of_credits} Credit`
            : `${transaction.plan_variant.duration} Days`;

        newTransaction["action"] = (
          <>
            {transaction.status === "Success" &&
              (newTransaction["action"] = (
                <div className="text-center">
                  <Tooltip title="Download Invoice" placement="bottom">
                    <i
                      onClick={() => {
                        transaction.status === "Success" &&
                          downloadUserInvoice(transaction.id);
                      }}
                      className="fa fa-file-pdf-o fa-2x icon-btn-action"
                    ></i>
                  </Tooltip>
                </div>
              ))}
          </>
        );

        return newTransaction;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const filter = () => (
    <>
      <DateRangeFilter
        handleChange={setFilter}
        selectedValue={paymentHistoryFilter?.created_at}
        filterKey="created_at"
        filterTitle="Date"
      />

      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder=" ID"
        filterKey="id"
        filterTitle="ID"
        searchedValue={paymentHistoryFilter?.id}
      />

      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Transaction ID"
        filterKey="transaction_id"
        filterTitle="Transaction ID"
        searchedValue={paymentHistoryFilter?.transaction_id}
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={paymentHistoryFilter?.status}
        filterKey="status"
        options={paymentStatus}
        filterTitle="Status"
        defaultOption="All"
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );
  return (
    <div className="finance__dashboard">
      <div className="finance__dashboard-card-row">
        <DashboardCard
          title={TODAY}
          details={today}
          handleChangeCard={handleChangeCard}
        />
        <DashboardCard
          title={THIS_MONTH}
          details={this_month}
          handleChangeCard={handleChangeCard}
        />
        <DashboardCard
          title={THIS_QUARTER}
          details={this_quarter}
          handleChangeCard={handleChangeCard}
        />
        <DashboardCard
          title={THIS_YEAR}
          details={this_year}
          handleChangeCard={handleChangeCard}
        />
      </div>

      <DataTable
        title="Transactions"
        addBtn={
          <>
            <Tooltip
              title="Download Transactions"
              placement="bottom"
              className="download__transaction_report"
            >
              <i
                className="fa fa-file-excel-o fa-2x icon-btn-action"
                onClick={downloadPaymentHistories}
              ></i>
            </Tooltip>
          </>
        }
        CreateTableData={configureTableData}
        withFilter={true}
        filter={filter}
        handleSetFilter={setFilter}
        count={count}
        skip={paymentHistoryFilter?.skip}
        isLoading={loadingPaymenthistories}
      />
    </div>
  );
};

export default Dashboard;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import { getCategorieList, getProductFilter } from "../selectors";
import { addProduct, getCategoryList, getProductsList } from "../actions";
import { brandsListParams, categoryParams } from "../constant";
import { getBrandsList } from "../../brand/actions";
import { getBrands } from "../../brand/selectors";
import { fileContext } from "../../../../../constants";

export const useAddProduct = () => {
  const dispatch = useDispatch();
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const categories = useSelector(getCategorieList);
  const brands = useSelector(getBrands);
  const productFilter = useSelector(getProductFilter);
  const [addProductLoading, setAddProductLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getCategories = async () => {
    setAddProductLoading(true);
    const is_success = await dispatch(getCategoryList(categoryParams));
    await dispatch(getBrandsList(brandsListParams));
    if (is_success) {
      toggleModal();
    }
    setAddProductLoading(false);
  };

  const handleAddProduct = async (values) => {
    try {
      const payload = {
        product: {
          name: values.title,
          status: values.status,
          category_id: values.categoryId.id,
          brand_id: values.brandId.id,
          about: values.about,
        },
        icon: files.find(
          (file) => file.file_context === fileContext.PRODUCT_ICON
        ),
        banner: files.find(
          (file) => file.file_context === fileContext.PRODUCT_BANNER
        ),
      };
      setAddProductLoading(true);
      const is_success = await dispatch(addProduct(payload));
      setAddProductLoading(false);
      if (is_success) {
        dispatch(getProductsList(productFilter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addProductLoading,
    isOpenModal,
    categories,
    brands,
    toggleModal,
    getCategories,
    handleAddProduct,
  };
};

import {
  RESET_MARKETPLACE_FILTER,
  SET_MARKETPLACE_FILTER,
  SET_MARKETPLACE_LIST,
} from "./actions";

const initialList = {
  marketplaces: [],
  count: 0,
  filter: {
    limit: 10,
    skip: 0,
  },
};

const marketplaceReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_MARKETPLACE_LIST:
      return {
        ...state,
        marketplaces: action.payload.marketplaces,
        count: action.payload.count || 0,
      };

    case SET_MARKETPLACE_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    case RESET_MARKETPLACE_FILTER:
      return { ...state, filter: initialList.filter || [] };
    default:
      return state;
  }
};

export default marketplaceReducer;

import React from "react";
import { Button } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import DataTable from "../../../components/data-table";
import { useMagazines } from "./hooks";
import { columns, statusOptions } from "./constant";
import SelectFilter from "../../../components/filters/select-filter";
import SearchFilter from "../../../components/filters/search-filter";
import DeleteMagazine from "./delete-magazine";
import DateRangeFilter from "../../../components/filters/date-range-filter";
import ActionButton from "../../../components/action-button";

const MagazineList = () => {
  const {
    magazines,
    loadingMagazines,
    setFilter,
    filters,
    magazineCount,
    handleEditClick,
    handleAddClick,
    resetFilter,
  } = useMagazines();

  const configureTableData = () => {
    try {
      let results = magazines.map((magazine) => {
        let newMagazine = Object.assign({}, magazine);
        newMagazine["action"] = (
          <div className="magazines__container">
            <ActionButton
              title="View"
              className="blog__pad_left10"
              onClick={() => handleEditClick(magazine?.id)}
              iconClassName={`fa fa-edit icon-btn-action`}
            />

            <DeleteMagazine magazine={magazine?.id} />
          </div>
        );

        return newMagazine;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const magazineFilter = () => (
    <>
      <DateRangeFilter
        handleChange={setFilter}
        selectedValue={filters.published_at}
        filterKey="published_at"
        filterTitle="Date"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        filterKey="status"
        options={statusOptions}
        defaultOption="All Statuses"
        filterTitle="Status"
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search ID"
        filterKey="id"
        filterTitle="ID"
        searchedValue={filters?.id}
      />
      <SearchFilter
        handleSearch={setFilter}
        searchPlaceholder="Search Title"
        filterTitle="Title"
        filterKey="title"
        searchedValue={filters?.title}
      />
      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={resetFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Magazines"
      addBtn={
        <>
          <Button className="btn btn-success btn-sm" onClick={handleAddClick}>
            <i className="fa fa-plus"></i> Add Magazine
          </Button>
        </>
      }
      CreateTableData={configureTableData}
      withFilter={true}
      filter={magazineFilter}
      handleSetFilter={setFilter}
      count={magazineCount}
      skip={filters?.skip}
      isLoading={loadingMagazines}
    />
  );
};

export default MagazineList;

import React, { useEffect } from "react";
import * as moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

import DataTable from "../../components/data-table";
import { useScheduleEvent } from "./hooks";
import {
  columns,
  eventTypes,
  scheduleEventStatus,
  scheduleEventAccessTypes,
} from "./constant";
import SelectFilter from "../../components/filters/select-filter";
import DateFilter from "../../components/filters/date-filter";
import DataSelectFilter from "../../components/filters/data-select-filter";
import AddScheduleEvent from "./add-schedule-event";
import EditScheduleEvent from "./edit-schedule-event";
import DeleteScheduleEvent from "./delete-schedule-event";
import CancelScheduleEvent from "./cancel-schedule-event";
import ShowAttendees from "./attendees-list";
import ShowReviews from "./reviews";
import AddNote from "./add-notes";
import ExportComponent from "./export";
import ActionButton from "../../components/action-button";
import { useHistory } from "react-router-dom";

const ScheduleEvents = () => {
  const {
    scheduleEvents,
    loadingScheduleEvent,
    setFilter,
    filters,
    categories,
    users,
    getAutocompleteValues,
    joinMeeting,
    scheduleEventCount,
    isAdmin,
    isModerator,
    isCoordinator,
    isStartingEvent,
    clearFilter,
    handleEventsFilter,
  } = useScheduleEvent();

  const history = useHistory();

  useEffect(() => {
    getAutocompleteValues();
    // eslint-disable-next-line
  }, []);

  const configureTableData = () => {
    try {
      const currentDate = new Date();
      let results = scheduleEvents.map((event) => {
        let newEvent = Object.assign({}, event);
        newEvent["hostName"] =
          event?.hosts[0]?.name || event?.hosts[0]?.mobile_number || "";
        newEvent["action"] =
          currentDate > new Date(event.ends_at) ? (
            <div className="categories__list-action">
              {moment(event.ends_at).isBefore(new Date()) && (
                <AddNote eventScheduleId={event.id} />
              )}
              <ShowReviews eventScheduleId={event.id} />
            </div>
          ) : (
            <div className="categories__list-action">
              {moment().isAfter(
                moment(event.starts_at).subtract(
                  event?.waiting_room_start_time
                    ? event?.waiting_room_start_time
                    : 15,
                  "minute"
                )
              ) &&
              moment().isBefore(event.ends_at) &&
              !event?.cancelled_at &&
              (isAdmin || isModerator || isCoordinator) ? (
                <Tooltip title="Join Meeting" placement="bottom">
                  <i
                    className={`fa fa-play-circle icon-btn-action ${
                      isStartingEvent ? "disabled-icon" : null
                    }`}
                    onClick={() => joinMeeting(event.id)}
                  ></i>
                </Tooltip>
              ) : null}

              {moment().isAfter(
                moment(event.starts_at).subtract(15, "minute")
              ) &&
              moment().isBefore(event.ends_at) &&
              (isAdmin || isModerator || isCoordinator) &&
              event?.broadcast_platform ? (
                <Tooltip
                  title="This session is Broadcasting"
                  placement="bottom"
                >
                  <i className={`fa fa-youtube-play icon-broadcast`}></i>
                </Tooltip>
              ) : null}

              <>
                <EditScheduleEvent
                  scheduleEvent={event}
                  handleEventsFilter={handleEventsFilter}
                />
                <CancelScheduleEvent scheduleEvent={event} />
                <DeleteScheduleEvent scheduleEvent={event} />

                {!isCoordinator && event?.allow_discussions ? (
                  <ActionButton
                    title="View Discussions"
                    iconClassName="fa fa-comments fa-2x icon-btn-action"
                    onClick={() =>
                      history.push({
                        pathname: `/schedule-event/${event?.id}/discussions`,
                      })
                    }
                  />
                ) : null}
              </>
            </div>
          );
        newEvent.starts_at = moment(event.starts_at).format(
          "DD/MM/YYYY @ hh:mm A "
        );
        newEvent.ends_at = moment(event.ends_at).format(
          "DD/MM/YYYY @ hh:mm A "
        );
        newEvent["is_free"] = event?.is_free ? "Free" : "Paid";
        newEvent["eventType"] = event?.type || "";
        newEvent.booked = (
          <ShowAttendees
            bookedInfo={`${event.booked}`}
            eventScheduleId={event.id}
          />
        );
        newEvent.allow_booking_post_commencement = moment(
          event?.allow_booking_post_commencement
        ).format("DD/MM/YYYY @ hh:mm A ");

        newEvent.allow_seats_increment = moment(
          event?.allow_seats_increment
        ).format("DD/MM/YYYY @ hh:mm A ");

        return newEvent;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results || [],
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const scheduleEventFilter = () => (
    <>
      <DateFilter
        handleChange={setFilter}
        selectedValue={filters.event_date}
        filterKey="event_date"
        filterTitle="Event Date"
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.status}
        filterKey="status"
        options={scheduleEventStatus}
        filterTitle="Status"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters.type}
        filterKey="type"
        options={scheduleEventAccessTypes}
        filterTitle="Event type"
      />
      {isAdmin ? (
        <DataSelectFilter
          handleSelect={setFilter}
          selectedValue={filters.host_ids.length > 0 && filters.host_ids[0]}
          filterKey="host_ids"
          options={users}
          filterTitle="Host"
          displayText="name"
          defaultOption="All Hosts"
        />
      ) : null}
      <DataSelectFilter
        handleSelect={setFilter}
        selectedValue={filters.category_ids[0]}
        filterKey="category_ids"
        options={categories}
        filterTitle="Category"
        displayText="title"
        defaultOption="All Categories"
      />

      <SelectFilter
        handleSelect={setFilter}
        selectedValue={
          filters.is_free === "" || filters?.is_free === undefined
            ? "All"
            : filters.is_free
            ? "Free"
            : "Paid"
        }
        filterKey="is_free"
        options={eventTypes}
        defaultOption="All"
        filterTitle="Type"
      />

      <Tooltip title="Clear filters" placement="bottom">
        <div className="filter__reset-all" onClick={clearFilter}>
          <i className="fa fa-filter filter-icon"></i>
          <i className="fa fa-times-circle remove-icon"></i>
        </div>
      </Tooltip>
    </>
  );

  return (
    <DataTable
      title="Scheduled Events"
      addBtn={
        <div className="row schedule_event__pad_left15">
          <AddScheduleEvent handleEventsFilter={handleEventsFilter} />
          <ExportComponent />
        </div>
      }
      CreateTableData={configureTableData}
      withFilter={true}
      filter={scheduleEventFilter}
      handleSetFilter={setFilter}
      count={scheduleEventCount}
      skip={filters?.skip}
      isLoading={loadingScheduleEvent}
    />
  );
};

export default ScheduleEvents;

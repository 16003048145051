import * as Yup from "yup";

export const productFormSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  status: Yup.string().required("Status is required"),
  about: Yup.array().required("About are required"),
  fileIcon: Yup.array().required("File Icon is required"),
  fileBanner: Yup.array().required("File Icon is required"),
  categoryId: Yup.string().required("Category is required"),
  brandId: Yup.string().required("Product is required"),

});

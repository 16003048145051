export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  // {
  //   Header: "Description",
  //   accessor: "description",
  //   disableGlobalFilter: true,
  // },
  {
    Header: "Type",
    accessor: "type",
    disableGlobalFilter: true,
  },
  {
    Header: "No Of Credits",
    accessor: "no_of_credits",
    disableGlobalFilter: true,
  },
  {
    Header: "Amount",
    accessor: "amount",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const planStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 3,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const planType = [
  {
    id: 1,
    value: "Free",
    displayText: "Free",
  },
  {
    id: 2,
    value: "Paid",
    displayText: "Paid",
  },
];

export const isUnlimited = [
  {
    id: 1,
    value: true,
    displayText: "Yes",
  },
  {
    id: 2,
    value: false,
    displayText: "No",
  },
];

export const codeTypes = [
  {
    id: 1,
    value: "Paid",
    displayText: "Paid",
  },
  {
    id: 2,
    value: "Free",
    displayText: "Free",
  },
   {
    id: 3,
    value: "TopUp",
    displayText: "TopUp",
  },
];

// export const codeTypes = [
//   {
//     id: 1,
//     value: "Free",
//     displayText: "Free",
//   },
//   {
//     id: 2,
//     value: "Silver",
//     displayText: "Silver",
//   },
//   {
//     id: 3,
//     value: "Bronze",
//     displayText: "Bronze",
//   },
//   {
//     id: 4,
//     value: "Gold",
//     displayText: "Gold",
//   },
//   {
//     id: 5,
//     value: "Platinum",
//     displayText: "Platinum",
//   },
//   {
//     id: 6,
//     value: "TopUp",
//     displayText: "TopUp",
//   },
// ];

export const planDuration = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const fieldsName = {
  code: "code",
  title: "title",
  description: "description",
  type: "type",
  no_of_credits: "no_of_credits",
  plan_amount: "plan_amount",
  carry_forward: "carry_forward",
  status: "status",
  variants: "variants",
  no_of_credits: "no_of_credits",
  duration: "duration",
  gross_amount: "gross_amount",
  discount_type: "discount_type",
  discount: "discount",
  amount: "amount",
  is_unlimited: "is_unlimited",
  partner_offer_ids: "partner_offer_ids",
  partner_offer_text: "partner_offer_text",
  card_bg_start_color: "card_bg_start_color",
  card_bg_end_color: "card_bg_end_color",
  card_text_color: "card_text_color",
  product_reference_id: "product_reference_id",
  language: "language",
};

export const discountTypes = [
  {
    id: 1,
    value: "Flat",
    displayText: "Flat",
  },
  {
    id: 2,
    value: "Percent",
    displayText: "Percent",
  },
];

export const planStatusAction = {
  Published: "Published",
  Unpublished: "Unpublished",
  publishBtn: "Publish",
  unpublishBtn: "Unpublish",
};

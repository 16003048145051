import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogData,
  editBlog,
  setBlogFilter,
  getBlogList,
  getLabelList,
  clearBlogFilter,
} from "../actions";
import { clearFiles, setFiles } from "../../../../../utils/upload-file/actions";
import {
  getCommonFilter,
  getBlogFilter,
} from "../selectors";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export const useEditBlog = ({
  match: {
    params: { blog_id },
  },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editBlogLoading, setEditBlogLoading] = useState(false);
  const banner = useSelector(({ fileReducer }) => fileReducer.files);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const filters = useSelector(getCommonFilter);
  const [labels, setLabels] = useState([]);
  const blogFilter = useSelector(getBlogFilter);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const getRequiredData = async () => {
      setEditBlogLoading(true);
      dispatch(clearFiles());
      const { blog, banner } = await dispatch(getBlogData(blog_id));
      if (blog) {
        blog.title = blog.title || "";
        setSelectedBlog(blog)
      }
      banner.forEach((file) => dispatch(setFiles(file)));
      const { labels } = await dispatch(getLabelList(filters));
      if (labels) {
        setLabels(labels);
      }
      setEditBlogLoading(false);
    };
    getRequiredData();
  }, [blog_id, dispatch, filters]);

  const handleEditBlog = async (values) => {
    try {
      setEditBlogLoading(true);
      const payload = {
        blog: {
          title: values.title,
          label_id: values.label_id ? values.label_id.id : 0,
          published_at: values.published_at,
          status: values.status,
          reference_link: values.reference_link,
        },
        banner: banner.length > 0 ? banner[0] : null,
      };
      const is_success  = await dispatch(editBlog(payload, blog_id));

      setEditBlogLoading(false);
      if (is_success) {
        dispatch(clearFiles());
        dispatch(clearBlogFilter());
        history.push("/senior-today-blogs");
      }
    } catch (error) {
      console.error("Error editing blog:", error);
    }
  };

  const setSearchText = useCallback(
    _.debounce((key, value) => {
      dispatch(setBlogFilter({ key, value }));
    }, 600),
    []
  );

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogList = await dispatch(getBlogList(blogFilter));
        if (blogList) {
          setBlogs(blogList);
        }
      } catch (error) {
        console.error("Error fetching blog list:", error);
      }
    };
    fetchBlogs();
  }, [dispatch, blogFilter]);

  return {
    editBlogLoading,
    handleEditBlog,
    blogs,
    labels,
    selectedBlog,
    setSearchText,
  };
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles, setFiles } from "../../../../../utils/upload-file/actions";
import { getMarketplaceFilter } from "../selectors";
import {
  editMarketPlace,
  getMarketPlacesList,
  getSingleMarketplace,
} from "../actions";
import { fileContext } from "../../../../../constants";

export const useEditMarketplace = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(getMarketplaceFilter);
  const [editMarketplaceLoading, setEditMarketplaceLoading] = useState(false);
  const [selectedMarketplace, setSelectedMarketplace] = useState();
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const getMarketplace = async () => {
    setEditMarketplaceLoading(true);
    dispatch(clearFiles());
    const data = await dispatch(getSingleMarketplace(id));
    if (data) {
      data.files.forEach((file) => {
        if (file) dispatch(setFiles(file));
      });
      setSelectedMarketplace(data.marketplace);
    }
    toggleModal();
    setEditMarketplaceLoading(false);
  };

  const handleEditMarketplace = async (values) => {
    const payload = {
      marketplace: {
        name: values.name,
        status: values.status,
      },
      logo: files.find((file) => file.file_context === fileContext.LOGO),
      banner: files.find((file) => file.file_context === fileContext.BANNER),
    };

    setEditMarketplaceLoading(true);
    const is_success = await dispatch(editMarketPlace(payload, id));
    setEditMarketplaceLoading(false);
    if (is_success) {
      setEditMarketplaceLoading(false);
      dispatch(getMarketPlacesList(filters));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    isOpenModal,
    selectedMarketplace,
    editMarketplaceLoading,
    getMarketplace,
    handleEditMarketplace,
    toggleModal,
  };
};

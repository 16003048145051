import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../../components/modal";
import { useEditMarketplace } from "./hooks";
import { useUser } from "../../../../../common-hooks/user";
import MarketplaceForm from "../form/marketplace-form";

const EditMarketplace = ({ marketplace }) => {
  const {
    isOpenModal,
    selectedMarketplace,
    editMarketplaceLoading,
    getMarketplace,
    handleEditMarketplace,
    toggleModal,
  } = useEditMarketplace(marketplace);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i className={`fa fa-edit icon-btn-action`} onClick={getMarketplace}></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <MarketplaceForm
              fieldValues={selectedMarketplace}
              handleMarketPlaceForm={handleEditMarketplace}
              btn="Save"
              isLoading={editMarketplaceLoading}
            />
          }
          title="Edit Brand"
        />
      ) : null}
    </>
  );
};

export default EditMarketplace;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../../utils/auth";
import { getCategoriesCount, getCategoriesFilter, getOfferCategories } from "./selector";
import { getOfferCategoryList, setOfferCategoryFilter } from "./actions";


export const useCategoryOffer = () => {
  clearLanguage();
  const dispatch = useDispatch();
  const categories = useSelector(getOfferCategories);
  const categoriesCount = useSelector(getCategoriesCount);
  const filters = useSelector(getCategoriesFilter);
  const [loadingCategories, setLoadingCategories] = useState(false);

  useEffect(() => {
    const fetchInterests = async () => {
      setLoadingCategories(true);
      await dispatch(getOfferCategoryList(filters));
      setLoadingCategories(false);
    };
    fetchInterests();
  }, [dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setOfferCategoryFilter({ key, value }));
  };

  return {
    categories,
    loadingCategories,
    categoriesCount,
    filters,
    setFilter,
  };
};

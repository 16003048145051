import { useState } from "react";
import { initialLanguageFieldValue } from "../../../../../constants";

export const useProductForm = ({
  fieldValues,
  handleInterestForm,
  btn,
  isLoading,
  categories,
  brands,
  handleLangugeData,
}) => {
  const formField = {
    title: "",
    status: "",
    categoryId: "",
    brandId: "",
  };
  const [currentProductIcon, setCurrentProductIcon] = useState([]);
  const [currentProductBanner, setcurrentProductBanner] = useState([]);
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const handleFormUpdate = (event) => {
    const { name, value } = event.target;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  const handleFormArrayUpdate = async (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };

  const handleCategoryUpdate = (name, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };
  return {
    currentProductBanner,
    currentProductIcon,
    handleInterestForm,
    btn,
    isLoading,
    categories,
    brands,
    handleLangugeData,
    formValues,
    handleCategoryUpdate,
    handleFormUpdate,
    setCurrentProductIcon,
    setcurrentProductBanner,
    handleFormArrayUpdate,
  };
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialLanguageFieldValue } from "../../../constants";
import { clearLanguage, getLanguage } from "../../../utils/auth";
import { editPlan, getPartnersOfferList, getPlan, getPlanList } from "../actions";
import { getPlansFilter, getPartnerOffer, getPartnersOfferFilter } from "../selectors";

export const useEditPlan = ({ id }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [editPlanLoading, setEditPlanLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const filter = useSelector(getPlansFilter);
  const partnerOffer = useSelector(getPartnerOffer);
  const partnerOfferFilter = useSelector(getPartnersOfferFilter);

  const getPlanFieldData = async () => {
    const selectedLanguage = getLanguage();
    setEditPlanLoading(true);
    const data = await dispatch(getPlan(id));
    await dispatch(getPartnersOfferList(partnerOfferFilter));

    if (data) {
      data.variants = data.plan_variants.map((item) => {
        const { partner_offer_ids } = item;
        return {
          ...item,
          partner_offer_ids: partnerOffer.filter((offer) => {
            return partner_offer_ids.find((id) => offer.id === id);
          }),
        };
      });
      data.no_of_credits = data.no_of_credits;
      data.plan_amount = data.amount;
      data.language = selectedLanguage;
      data.title = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.title || "",
      };

      data.description = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.description || "",
      };

      data.partner_offer_text = {
        ...initialLanguageFieldValue,
        [selectedLanguage]: data.partner_offer_text || "",
      };

      setSelectedPlan(data);
      toggleModal();
    }
    setEditPlanLoading(false);
  };

  const handleLangugeData = async (rest, setFormValues) => {
    const selectedLanguage = getLanguage();
    const data = await dispatch(getPlan(id));

    if (data) {
      data.variants = data.plan_variants.map((item) => {
        const { partner_offer_ids } = item;
        return {
          ...item,
          partner_offer_ids: partnerOffer.filter((offer) => {
            return partner_offer_ids.find((id) => offer.id === id);
          }),
        };
      });
      data.no_of_credits = data.no_of_credits;
      data.plan_amount = data.amount;
      data.language = selectedLanguage;

      data.title = {
        ...rest.values.title,
        [selectedLanguage]:
          rest.values.title[selectedLanguage] || data?.title || "",
      };

      data.description = {
        ...rest.values.description,
        [selectedLanguage]:
          rest.values.description[selectedLanguage] || data?.description || "",
      };

      data.partner_offer_text = {
        ...rest.values.partner_offer_text,
        [selectedLanguage]:
          rest.values.partner_offer_text[selectedLanguage] ||
          data?.partner_offer_text ||
          "",
      };

      setSelectedPlan(data);
      setFormValues(data);
    }
  };

  const handleEditPlan = async (values) => {
    const variantData = [];
    values.variants.forEach((v) => {
      variantData.push({
        no_of_credits: v.no_of_credits,
        duration: v.duration,
        gross_amount: v.gross_amount,
        discount_type: v.discount_type,
        discount: v.discount,
        amount: v.amount,
        product_reference_id: v.product_reference_id,
        partner_offer_ids: partnerOffer.filter((offer) =>
          v.partner_offer_ids.find((id) => id === offer.id)
        ),
      });
    });

    const payload = {
      plan: {
        code: values.code,
        title: values.title,
        description: values.description,
        status: values.status,
        no_of_credits: values.no_of_credits,
        carry_forward: values.carry_forward,
        type: values.type,
        amount: values.type === "Free" ? 0 : values.plan_amount,
        is_unlimited: values.is_unlimited,
        partner_offer_text: values.partner_offer_text,
        card_bg_start_color: values.card_bg_start_color,
        card_bg_end_color: values.card_bg_end_color,
        card_text_color: values.card_text_color,
      },
      plan_variants: values.variants.map((variant) => {
        return {
          no_of_credits: variant.no_of_credits,
          duration: variant.duration,
          gross_amount: parseInt(variant.gross_amount),
          discount_type: variant.discount_type,
          discount: variant.discount,
          amount: variant.amount,
          product_reference_id: variant.product_reference_id || "",
          partner_offer_ids: variant.partner_offer_ids.map((offer) => offer.id),
        };
      }),
    };
    setEditPlanLoading(true);
    const is_success = await dispatch(editPlan(payload, id));
    setEditPlanLoading(false);
    if (is_success) {
      dispatch(getPlanList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    clearLanguage();
  };

  return {
    editPlanLoading,
    handleEditPlan,
    isOpenModal,
    toggleModal,
    getPlanFieldData,
    selectedPlan,
    handleLangugeData,
  };
};

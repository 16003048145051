import React from "react";
import { Button } from "reactstrap";

import { useAddMarketPlace } from "./hooks";
import Modals from "../../../../../components/modal";
import { useUser } from "../../../../../common-hooks/user";
import MarketplaceForm from "../form/marketplace-form";

const AddMarketplace = (props) => {
  const {
    addMarketplaceLoading,
    isOpenModal,
    toggleModal,
    handleAddMarketplace,
  } = useAddMarketPlace(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Marketplace
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <MarketplaceForm
              handleMarketPlaceForm={handleAddMarketplace}
              btn="Add"
              isLoading={addMarketplaceLoading}
            />
          }
          title="Add Marketplace"
        />
      ) : null}
    </div>
  );
};

export default AddMarketplace;

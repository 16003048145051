import React from "react";
import { Button } from "reactstrap";

import { useAddProduct } from "./hooks";
import Modals from "../../../../../components/modal";
import { useUser } from "../../../../../common-hooks/user";
import ProductForm from "../form/product-form";

const AddProduct = (props) => {
  const {
    addProductLoading,
    isOpenModal,
    categories,
    brands,
    toggleModal,
    getCategories,
    handleAddProduct,
  } = useAddProduct(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          getCategories();
        }}
      >
        <i className="fa fa-plus"></i> Add Product
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ProductForm
              handleInterestForm={handleAddProduct}
              btn="Add"
              isLoading={addProductLoading}
              categories={categories}
              brands={brands}
            />
          }
          title="Add Product"
        />
      ) : null}
    </div>
  );
};

export default AddProduct;

import React from "react";
import DataTable from "../../../../components/table";
import { useProducts } from "./hooks";
import { columns } from "./constant";
import EditProduct from "./edit-product";
import AddProduct from "./add-product";

const Products = () => {
  const { products, loadingProducts, filters, productsCount, setFilter } =
    useProducts();

  const configureTableData = () => {
    try {
      let results = products.map((product) => {
        let newProducts = Object.assign({}, product);
        newProducts["action"] = (
          <div className="categories__list-action">
            <EditProduct product={product} />
          </div>
        );
        return newProducts;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Products"
      addBtn={<AddProduct />}
      CreateTableData={configureTableData}
      withFilter={false}
      handleSetFilter={setFilter}
      count={productsCount}
      skip={filters?.skip}
      isLoading={loadingProducts}
    />
  );
};

export default Products;

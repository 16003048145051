import { useState } from "react";
import { useDispatch } from "react-redux";
import {  editOfferCategory } from "../actions";


export const useEditInterest = ({ id }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editCategoryLoading, setEditCategoryLoading] = useState(false);


  const handleEditCategory = async (values) => {
    try {
      const payload = {
        interest: {
          title: values.title,
          status: values.status,
        },
      };
      setEditCategoryLoading(true);
      const is_success = await dispatch(editOfferCategory(payload, id));
      setEditCategoryLoading(false);
      if (is_success) {
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    editCategoryLoading,
    isOpenModal,
    handleEditCategory,
    toggleModal,
  };
};

import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";
import { clearFiles } from "../../../../utils/upload-file/actions";

const namespace = "containers/senior-today-blogs";

export const SET_BLOG_LIST = `${namespace}/SET_BLOG_LIST`;
export const SET_BLOG_FILTER = `${namespace}/SET_BLOG_FILTER`;
export const CLEAR_BLOG_FILTER = `${namespace}/CLEAR_BLOG_FILTER`;
export const SET_USER_FILTER = `${namespace}/SET_USER_FILTER`;
export const CLEAR_USER_FILTER = `${namespace}/CLEAR_USER_FILTER`;

const setBlogList = (payload) => ({
  type: SET_BLOG_LIST,
  payload,
});

export const setBlogFilter = (payload) => ({
  type: SET_BLOG_FILTER,
  payload,
});

export const clearBlogFilter = (payload) => ({
  type: CLEAR_BLOG_FILTER,
  payload,
});


export const createSeniorTodayBlog = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.createSeniorTodayBlog, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSeniorTodayBlogs, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    
    if (is_success) {
      dispatch(setBlogList(data));
      return data?.blogs;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getBlogData = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSeniorTodayBlog(id));
    const { data } = response;
    if (data.is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editBlog = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateSeniorTodayBlog(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );

      dispatch(clearFiles());
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteBlogData = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteSeniorTodayBlog(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getLabelList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getLabels, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};





import React from "react";
import DataTable from "../../../components/table";
import AddTestimonial from "./add-testimonial";
import { useTestimonial } from "./hooks";
import { columns, testimonialStatus, testimonialType } from "./constant";
import SelectFilter from "../../../components/filters/select-filter";
import EditTestimonial from "./edit-testimonial";
import DeleteTestimonial from "./delete-testimonial";

function Testimonial() {
  const {
    testimonials,
    loadingTestimonial,
    setFilter,
    filters,
    testimonialsCount,
  } = useTestimonial();

  const configureTableData = () => {
    try {
      let results = testimonials.map((testimonial) => {
        let newTestimonial = Object.assign({}, testimonial);

        newTestimonial["action"] = (
          <div className="categories__list-action">
            <EditTestimonial testimonial={testimonial} />
            <DeleteTestimonial testimonial={testimonial} />
          </div>
        );

        return newTestimonial;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  const testimonialFilter = () => (
    <>
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.status}
        options={testimonialStatus}
        filterKey="status"
        defaultOption="All"
        filterTitle="Status"
      />
      <SelectFilter
        handleSelect={setFilter}
        selectedValue={filters?.type}
        options={testimonialType} 
        filterKey="type"
        defaultOption="All"
        filterTitle="Type"
      />
    </>
  );

  return (
    <DataTable
      title="Testimonials"
      addBtn={<AddTestimonial />}
      CreateTableData={configureTableData}
      withFilter={true}
      filter={testimonialFilter}
      handleSetFilter={setFilter}
      skip={filters?.skip}
      count={testimonialsCount}
      isLoading={loadingTestimonial}
    />
  );
}

export default Testimonial;

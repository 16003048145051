import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import { DataLoader } from "../../../../../components/loader";
import { generatePaymentLinkFieldNames, codeTypes } from "../../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { useGeneratePaymentLinkForm } from "./hooks";
import { generatePaymentLinkSchema } from "./generate-payment-link-schema";
import Tooltip from "@material-ui/core/Tooltip";

const GeneratePaymentLinkForm = ({
  btn,
  isLoading,
  handleGenerateLink,
  copyLink,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { code, plan_variants, price, type } = generatePaymentLinkFieldNames;
  const { plans, payment_link, fetchPlans, handleFormUpdate, formValues } =
    useGeneratePaymentLinkForm();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={generatePaymentLinkSchema}
      initialValues={{
        code: formValues?.code || "",
        plan_variants: formValues?.plan_variants || "",
        type: formValues?.type || "",
        price: formValues?.price || "",
      }}
      onSubmit={handleGenerateLink}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div>
              <TextField
                className="material-form__field"
                name={code}
                placeholder="Type"
                label="Type"
                select
                value={rest.values.code}
                onChange={(e) => {
                  const { value } = e.target;
                  handleFormUpdate(e);
                  handleFormValueChange(rest, code);
                  fetchPlans(value);
                }}
                onFocus={() => handleFocusField(code)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.code}
              >
                {codeTypes.map((code) => (
                  <MenuItem
                    key={code.id}
                    className="material-form__option"
                    value={code.value}
                  >
                    {code.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === code ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={code}
                />
              )}
            </div>

            {rest.values.code && (
              <div>
                <TextField
                  className="material-form__field"
                  name={type}
                  placeholder="Code"
                  label="Code"
                  select
                  value={rest.values.type}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, type);
                    handleFormUpdate(e);
                  }}
                  onFocus={() => handleFocusField(type)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.type}
                >
                  {plans.length === 0 && (
                    <MenuItem className="material-form__option">
                      No plans available for this code
                    </MenuItem>
                  )}
                  {plans.map((plan) => (
                    <MenuItem
                      key={plan.id}
                      className="material-form__option"
                      value={plan}
                    >
                      {plan?.title}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === type ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={type}
                  />
                )}
              </div>
            )}

            {rest.values.type && (
              <div>
                <TextField
                  className="material-form__field"
                  name={plan_variants}
                  placeholder="Variants"
                  label="Variants"
                  select
                  value={rest.values.plan_variants}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);
                    handleFormValueChange(rest, plan_variants);
                    handleFormUpdate(e);
                  }}
                  onFocus={() => handleFocusField(plan_variants)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.plan_variants}
                >
                  {plans.length === 0 && (
                    <MenuItem className="material-form__option">
                      No Variants available for this code
                    </MenuItem>
                  )}
                  {rest.values?.type?.plan_variants?.map((variant) => (
                    <MenuItem
                      key={variant.id}
                      className="material-form__option"
                      value={variant}
                    >
                      {rest.values.code === "TopUp"
                        ? `${variant.no_of_credits} credits`
                        : `${variant.duration} days`}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === plan_variants ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={plan_variants}
                  />
                )}
              </div>
            )}

            {rest.values.plan_variants && (
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={price}
                  disabled
                  placeholder="Price"
                  label="Price"
                  type="text"
                  value={`₹ ${rest.values.plan_variants["amount"]}` || ""}
                  onFocus={() => handleFocusField(price)}
                  onBlur={() => handleBlurField()}
                />
              </div>
            )}

            <button
              disabled={payment_link ? true : false}
              type="submit"
              className="btn btn-success btn-right btn-sm"
            >
              {btn}
            </button>

            {payment_link && (
              <div className="user__payment-link">
                <p className="user__payment-link-text">
                  {payment_link.slice(0, 60) + "..."}
                </p>
                <Tooltip title="Click to copy" placement="bottom">
                  <span className="user__payment-link-icon">
                    <i
                      onClick={() => copyLink(payment_link)}
                      className="fa fa-copy fa-fw icon-btn-action"
                    ></i>
                  </span>
                </Tooltip>
              </div>
            )}
          </form>
        </>
      )}
    </Formik>
  );
};

export default GeneratePaymentLinkForm;

import React from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, Chip, MenuItem } from "@material-ui/core";
import {
  fieldNames,
  starredOptions,
  statusOptions,
  statusOptionsPublished,
  typeOptions,
  statusOptionsCancelled,
  fieldInfo,
} from "../constant";
import { DataLoader } from "../../../components/loader";
import TextfieldChips from "../../../components/text-field-chips";
import { blogFormSchema } from "./validation-schema";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import DateTimePicker from "../../../components/date-time-picker";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../utils/form";
import { useUploadFiles } from "../../../utils/upload-file/hooks";
import {
  fileContext,
  initialLanguageArrayValue,
  languages,
} from "../../../constants";
import ProgressChip from "../../../components/progress-chip";
import {
  scheduleEventLanguage,
  scheduleEventMode,
} from "../../schedule-events/constant";
import { useCourseScheduleForm } from "./hooks";
import * as moment from "moment";
import { setLanguage } from "../../../utils/auth";
import { InputProps } from "../../../components/input-props";

const CourseForm = ({
  fieldValues,
  handleCourseForm,
  btn,
  isLoading,
  users,
  courses,
  events,
  handleLanguageData,
  isEdit,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    handleFormUpdate,
    formValues,
    handleAddClickRequirements,
    handleRemoveClickRequirements,
    handleFormUpdateRequirements,
    handleAddClickEventSchedules,
    handleRemoveClickEventSchedules,
    handleFormUpdateEventSchedules,
    handleRegularEventChange,
    handleTextfieldChange,
    handleRemoveDescription,
    setFormValues,
    handleDescriptionField,
    updateRequirement,
  } = useCourseScheduleForm({
    fieldValues,
    btn,
    isLoading,
    users,
    courses,
    events,
  });

  const {
    uploadError,
    handleDeleteAFile,
    files,
    uploadProgress,
    isFileUploadData,
    handleAttachmentFileCourse,
    handleAttachmentCourseNote,
  } = useUploadFiles();

  const {
    event_id,
    mode,
    language,
    attendees_limit,
    current_attendees_limit,
    exceeds_percentage,
    increment_percentage,
    // commence_at,
    per_session_cost,
    status,
    hostIds,
    cohostIds,
    starred,
    allow_booking_post_commencement,
    allow_seats_increment,
    requirements,
    event_schedules,
    title,
    description,
    pre_event_file,
    pre_event_file_cta,
    note,
    form_language,
    waiting_room_start_time,
  } = fieldNames;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={blogFormSchema}
      initialValues={{
        event_id:
          (formValues?.event_id &&
            events.find((event) => event.id === formValues?.event_id?.id)) ||
          null,
        mode: formValues?.mode || "Online",
        language: formValues?.language || "",
        // commence_at:
        //   (formValues?.commence_at && new Date(formValues.commence_at)) || "",
        per_session_cost: formValues?.per_session_cost || 0,
        status: formValues?.status || "",
        hostIds:
          (formValues?.hostIds &&
            users.find((user) => user.id === formValues?.hostIds.id)) ||
          null,
        cohostIds:
          (formValues?.cohostIds &&
            users.filter((user) =>
              formValues.cohostIds.find((cohost) => cohost.id === user.id)
            )) ||
          [],
        booked: formValues?.booked || 0,
        attendees_limit: formValues?.attendees_limit || "",
        current_attendees_limit: formValues?.current_attendees_limit || "",
        increment_percentage: formValues?.increment_percentage || "",
        exceeds_percentage: formValues?.exceeds_percentage || "",
        starred:
          (formValues?.starred &&
            starredOptions.find(
              (starred) =>
                starred?.id === formValues?.starred?.id ||
                formValues?.starred === starred?.value
            )) ||
          null,
        allow_booking_post_commencement:
          (formValues?.allow_booking_post_commencement &&
            new Date(formValues.allow_booking_post_commencement)) ||
          "",
        allow_seats_increment:
          (formValues?.allow_seats_increment &&
            new Date(formValues.allow_seats_increment)) ||
          "",
        requirements: formValues?.requirements || initialLanguageArrayValue,
        form_language: formValues?.form_language || "en",
        waiting_room_start_time: formValues?.waiting_room_start_time
          ? formValues?.waiting_room_start_time
          : "",
        event_schedules:
          formValues?.event_schedules?.length > 0
            ? formValues?.event_schedules
            : [],
        waiting_room_start_time: formValues?.waiting_room_start_time
          ? formValues.waiting_room_start_time
          : "",
      }}
      onSubmit={handleCourseForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-6">
                <div className="col-11 schedule__event_left_pad0">
                  <p className="bold-text">Course Details</p>
                </div>
                {!isLoading && (
                  <div className="materila-form_inline-child sticky__element">
                    <TextField
                      className="material-form__field"
                      name={form_language}
                      placeholder="Language"
                      label="Language"
                      select
                      value={rest.values.form_language}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        handleFormValueChange(rest, name);
                        handleFormUpdate(name, value);
                        setFieldValue(name, value);
                        rest.values.event_schedules.forEach((event, i) => {
                          if (!event.title[value]) {
                            let list = [...rest.values.event_schedules];
                            list[i][title] = {
                              ...list[i][title],
                              [value]: "",
                            };

                            setFormValues((prevFieldValue) => ({
                              ...prevFieldValue,
                              event_schedules: list,
                            }));
                            setFieldValue(event_schedules, list);
                          }

                          if (!event.chapters[value]) {
                            let list = [...rest.values.event_schedules];
                            list[i]["chapters"] = {
                              ...list[i]["chapters"],
                              [value]: [],
                            };

                            setFormValues((prevFieldValue) => ({
                              ...prevFieldValue,
                              event_schedules: list,
                            }));
                            setFieldValue(event_schedules, list);
                          }
                        });

                        if (!rest.values.requirements[value]) {
                          setFormValues((prevFieldValue) => ({
                            ...prevFieldValue,
                            requirements: {
                              ...prevFieldValue.requirements,
                              [value]: [],
                            },
                          }));
                          setFieldValue(requirements, {
                            ...rest.values.requirements,
                            [value]: [],
                          });
                        }
                        if (btn === "Save") {
                          setLanguage(value);
                          handleLanguageData(rest, setFormValues);
                        }

                        if (btn === "Add") {
                          setLanguage(value);
                          rest.values.event_id &&
                            updateRequirement(rest.values.event_id, value);
                        }
                      }}
                      onFocus={() => handleFocusField(form_language)}
                      onBlur={() => handleBlurField()}
                      defaultValue={rest.values.form_language}
                    >
                      {languages.map((language) => (
                        <MenuItem
                          key={language.id}
                          className="material-form__option"
                          value={language.value}
                        >
                          {language.displayText}
                        </MenuItem>
                      ))}
                    </TextField>
                    {focusField === form_language ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={form_language}
                      />
                    )}
                  </div>
                )}

                <Autocomplete
                  options={events}
                  getOptionLabel={(event) => (event.title ? event.title : "")}
                  value={rest.values.event_id}
                  name={event_id}
                  onChange={(event, value) => {
                    setFieldValue(event_id, value);
                    handleFormUpdate(event_id, value);
                    // handleEventChange(value, setFieldValue);
                  }}
                  onFocus={() => handleFocusField(event_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={event_id}
                      placeholder="Select Event"
                      label="Select Event"
                      variant="standard"
                    />
                  )}
                  disabled={btn === "Save" && fieldValues.status !== "Draft"}
                />
                {focusField === event_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={event_id}
                  />
                )}

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={mode}
                      placeholder="Mode"
                      label="Mode"
                      select
                      value={rest.values.mode}
                      onChange={(event) => {
                        handleFormValueChange(rest, mode);
                        handleFormUpdate(mode, event.target.value);
                      }}
                      onFocus={() => handleFocusField(mode)}
                      onBlur={() => handleBlurField()}
                      disabled={
                        btn === "Save" && fieldValues.status !== "Draft"
                      }
                    >
                      {scheduleEventMode.map((mode) => (
                        <MenuItem
                          key={mode.id}
                          className="material-form__option"
                          value={mode.value}
                        >
                          {mode.displayText}
                        </MenuItem>
                      ))}
                    </TextField>
                    {focusField === mode ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={mode}
                      />
                    )}
                  </div>

                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={language}
                      placeholder="Language"
                      label="Language"
                      select
                      onChange={(event) => {
                        handleFormValueChange(rest, language);
                        handleFormUpdate(language, event.target.value);
                      }}
                      onFocus={() => handleFocusField(language)}
                      onBlur={() => handleBlurField()}
                      value={rest.values.language}
                    >
                      {scheduleEventLanguage.map((language) => (
                        <MenuItem
                          key={language.id}
                          className="material-form__option"
                          value={language.value}
                        >
                          {language.displayText}
                        </MenuItem>
                      ))}
                    </TextField>
                    {focusField === language ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name="language"
                      />
                    )}
                  </div>
                </div>

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={per_session_cost}
                      placeholder="Ex.5"
                      label="Per Session Credits"
                      type="number"
                      value={rest.values.per_session_cost}
                      onChange={(event) => {
                        handleFormValueChange(rest, per_session_cost);
                        handleFormUpdate(per_session_cost, event.target.value);
                      }}
                      onFocus={() => handleFocusField(per_session_cost)}
                      onBlur={() => handleBlurField()}
                      disabled={
                        btn === "Save" && fieldValues.status !== "Draft"
                      }
                    />
                    {focusField === per_session_cost ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={per_session_cost}
                      />
                    )}
                  </div>

                  {isEdit && (
                    <div className="materila-form_inline-child">
                      <TextField
                        className="material-form__field"
                        name={`booked`}
                        placeholder="Booked Seats"
                        label="Booked Seats"
                        type="number"
                        value={rest.values.booked || 0}
                      />
                    </div>
                  )}
                </div>

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={attendees_limit}
                      placeholder="Optimum Batch Size"
                      label="Optimum Batch Size"
                      type="number"
                      value={rest.values.attendees_limit}
                      onChange={(event) => {
                        handleFormValueChange(rest, attendees_limit);
                        handleFormUpdate(attendees_limit, event.target.value);
                        handleRegularEventChange(
                          attendees_limit,
                          event.target.value,
                          rest,
                          setFieldValue
                        );
                      }}
                      onFocus={(e) => {
                        const { name } = e.target;
                        handleFocusField(name);
                      }}
                      onBlur={() => handleBlurField()}
                      InputProps={InputProps(fieldInfo.attendees_limit)}
                    />
                    {focusField === attendees_limit ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={attendees_limit}
                      />
                    )}
                  </div>

                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={current_attendees_limit}
                      placeholder="Displayed Seats Count"
                      label="Displayed Seats Count"
                      type="number"
                      value={rest.values.current_attendees_limit}
                      onChange={(event) => {
                        handleFormValueChange(rest, current_attendees_limit);
                        handleFormUpdate(
                          current_attendees_limit,
                          event.target.value
                        );
                        handleRegularEventChange(
                          current_attendees_limit,
                          event.target.value,
                          rest,
                          setFieldValue
                        );
                      }}
                      onFocus={(e) => {
                        const { name } = e.target;
                        handleFocusField(name);
                      }}
                      onBlur={() => handleBlurField()}
                      InputProps={InputProps(fieldInfo.current_attendees_limit)}
                    />
                    {focusField === current_attendees_limit ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={current_attendees_limit}
                      />
                    )}
                  </div>
                </div>

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={exceeds_percentage}
                      placeholder="Threshold % for changing Displayed Seats Count"
                      label="Threshold % for changing Displayed Seats Count (in %)"
                      type="number"
                      value={rest.values.exceeds_percentage}
                      onChange={(event) => {
                        handleFormValueChange(rest, exceeds_percentage);
                        handleFormUpdate(
                          exceeds_percentage,
                          event.target.value
                        );
                        handleRegularEventChange(
                          exceeds_percentage,
                          event.target.value,
                          rest,
                          setFieldValue
                        );
                      }}
                      onFocus={(e) => {
                        const { name } = e.target;
                        handleFocusField(name);
                      }}
                      onBlur={() => handleBlurField()}
                      InputProps={InputProps(fieldInfo.exceeds_percentage)}
                    />
                    {focusField === exceeds_percentage ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={exceeds_percentage}
                      />
                    )}
                  </div>
                </div>

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={increment_percentage}
                      placeholder="% for updating new Displayed Seats Count"
                      label="% for updating new Displayed Seats Count (in %)"
                      type="number"
                      value={rest.values.increment_percentage}
                      onChange={(event) => {
                        handleFormValueChange(rest, increment_percentage);
                        handleFormUpdate(
                          increment_percentage,
                          event.target.value
                        );
                        handleRegularEventChange(
                          increment_percentage,
                          event.target.value,
                          rest,
                          setFieldValue
                        );
                      }}
                      onFocus={(e) => {
                        const { name } = e.target;
                        handleFocusField(name);
                      }}
                      onBlur={() => handleBlurField()}
                      InputProps={InputProps(fieldInfo.increment_percentage)}
                    />
                    {focusField === increment_percentage ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={increment_percentage}
                      />
                    )}
                  </div>
                </div>

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <DateTimePicker
                      selectedDate={rest.values.allow_booking_post_commencement}
                      handleChange={(value) => {
                        setFieldValue(allow_booking_post_commencement, value);
                        handleFormUpdate(
                          allow_booking_post_commencement,
                          value
                        );
                        handleRegularEventChange(
                          allow_booking_post_commencement,
                          value,
                          rest,
                          setFieldValue
                        );
                      }}
                      id="allow_booking_post_commencement"
                      name={allow_booking_post_commencement}
                      label="Last Date and Time to book seats"
                      customInput={
                        <TextField
                          className="material-form__field"
                          id="allow_booking_post_commencement"
                          label="Last Date and Time to book seats"
                          autoComplete="off"
                          InputProps={InputProps(
                            fieldInfo.allow_booking_post_commencement
                          )}
                        />
                      }
                    />

                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={allow_booking_post_commencement}
                    />
                  </div>
                </div>

                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <DateTimePicker
                      selectedDate={rest.values.allow_seats_increment}
                      handleChange={(value) => {
                        setFieldValue(allow_seats_increment, value);
                        handleFormUpdate(allow_seats_increment, value);
                        handleRegularEventChange(
                          allow_seats_increment,
                          value,
                          rest,
                          setFieldValue
                        );
                      }}
                      id="allow_seats_increment"
                      name={allow_seats_increment}
                      label="Last Date and Time to Increase Displayed Seat Count"
                      customInput={
                        <TextField
                          className="material-form__field"
                          id="allow_seats_increment"
                          label="Last Date and Time to Increase Displayed Seat Count"
                          autoComplete="off"
                          InputProps={InputProps(
                            fieldInfo.allow_seats_increment
                          )}
                        />
                      }
                    />
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={allow_seats_increment}
                    />
                  </div>
                </div>
                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={waiting_room_start_time}
                      placeholder="Waiting room time interval"
                      label=" Pre-Start Waiting Room Interval (in minutes)"
                      type="number"
                      value={rest.values.waiting_room_start_time}
                      onChange={(event) => {
                        handleFormValueChange(rest, waiting_room_start_time);
                        handleFormUpdate(
                          waiting_room_start_time,
                          event.target.value
                        );
                        handleRegularEventChange(
                          waiting_room_start_time,
                          event.target.value,
                          rest,
                          setFieldValue
                        );
                      }}
                      onFocus={(e) => {
                        const { name } = e.target;
                        handleFocusField(name);
                      }}
                      onBlur={() => handleBlurField()}
                    />
                  </div>
                </div>
                {/* <div className="materila-form_inline-fields">
                    <TextField
                      className="material-form__field"
                      name={waiting_room_start_time}
                      placeholder="Waiting room time interval"
                      label=" Pre-Start Waiting Room Interval (in minutes)"
                      type="number"
                      value={rest.values.waiting_room_start_time}
                      onChange={(e) => {
                        handleFormUpdate(e);
                        handleFormValueChange(rest, waiting_room_start_time);
                      }}
                      onFocus={() => handleFocusField(waiting_room_start_time)}
                      onBlur={() => handleBlurField()}
                      InputProps={InputProps(
                        fieldInfo.after_popup_broadcast_mins
                      )}
                    />
                  </div> */}
                <div className="material-form_inline-fields">
                  <div className="materila-form_inline-child">
                    <TextField
                      className="material-form__field"
                      name={status}
                      placeholder="Status"
                      label="Select Status"
                      select
                      value={rest.values.status}
                      onChange={(event) => {
                        handleFormValueChange(rest, status);
                        handleFormUpdate(status, event.target.value);
                      }}
                      onFocus={() => handleFocusField(status)}
                      onBlur={() => handleBlurField()}
                      disabled={
                        btn === "Save" && fieldValues.status === "Cancelled"
                      }
                      defaultValue={rest.values.status}
                    >
                      {btn === "Save" && fieldValues.status === "Cancelled"
                        ? statusOptionsCancelled.map((status) => (
                            <MenuItem
                              key={status.id}
                              className="material-form__option"
                              value={status.value}
                            >
                              {status.displayText}
                            </MenuItem>
                          ))
                        : btn === "Save" && fieldValues.status !== "Draft"
                        ? statusOptionsPublished.map((status) => (
                            <MenuItem
                              key={status.id}
                              className="material-form__option"
                              value={status.value}
                            >
                              {status.displayText}
                            </MenuItem>
                          ))
                        : statusOptions.map(
                            (status) =>
                              status.displayText !== "Cancelled" && (
                                <MenuItem
                                  key={status.id}
                                  className="material-form__option"
                                  value={status.value}
                                >
                                  {status.displayText}
                                </MenuItem>
                              )
                          )}
                    </TextField>
                    {focusField === status ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={status}
                      />
                    )}
                  </div>

                  <div className="materila-form_inline-child">
                    <Autocomplete
                      options={starredOptions}
                      getOptionLabel={(val) =>
                        val?.displayText ? val.displayText : ""
                      }
                      name={starred}
                      value={rest.values.starred}
                      onChange={(event, value) => {
                        setFieldValue(starred, value);
                        handleFormUpdate(starred, value);
                      }}
                      onFocus={() => handleFocusField(starred)}
                      onBlur={() => handleBlurField()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="material-form__field"
                          name={starred}
                          placeholder="Select Starred"
                          label="Select Starred"
                          variant="standard"
                        />
                      )}
                      disabled={
                        btn === "Save" && fieldValues.status !== "Draft"
                      }
                    />
                    {focusField === starred ? null : (
                      <ErrorMessage
                        render={(msg) => (
                          <div className="material-form__error">{msg}</div>
                        )}
                        name={starred}
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <Autocomplete
                    options={users}
                    getOptionLabel={(user) => (user.name ? user.name : "")}
                    value={rest.values.hostIds}
                    onChange={(event, value) => {
                      setFieldValue(hostIds, value);
                      handleFormUpdate(hostIds, value);
                    }}
                    onFocus={() => handleFocusField(hostIds)}
                    onBlur={() => {
                      handleBlurField();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        name={hostIds}
                        placeholder="Select Host"
                        label="Select Host"
                        variant="standard"
                      />
                    )}
                  />
                  {focusField === hostIds ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={hostIds}
                    />
                  )}
                </div>

                <div className="">
                  <Autocomplete
                    multiple
                    options={users}
                    getOptionLabel={(user) => (user.name ? user.name : "")}
                    value={rest.values.cohostIds}
                    onChange={(event, value) => {
                      setFieldValue(cohostIds, value);
                      handleFormUpdate(cohostIds, value);
                    }}
                    onFocus={() => handleFocusField(cohostIds)}
                    onBlur={() => {
                      handleBlurField();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="material-form__field"
                        name={cohostIds}
                        placeholder="Select Co-Host"
                        label="Select Co-Host"
                        variant="standard"
                      />
                    )}
                  />
                  {focusField === cohostIds ? null : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form__error">{msg}</div>
                      )}
                      name={cohostIds}
                    />
                  )}
                </div>

                <div className="section__container">
                  <div className="form__inline-items">
                    <div className="col-11 schedule__event_left_pad0">
                      <p className="bold-text">Requirements</p>
                    </div>

                    <div className="col-1 schedule__event_right_pad0">
                      <Tooltip title="Add" placement="bottom" className="">
                        <i
                          className={`fa fa-plus icon-btn-action text-success`}
                          onClick={() =>
                            handleAddClickRequirements(rest, setFieldValue)
                          }
                        ></i>
                      </Tooltip>
                    </div>
                  </div>
                  {rest?.values?.requirements[rest.values.form_language].map(
                    (x, i) => {
                      return (
                        <div key={x + i} className="custom__div__dynamic__form">
                          <div className="form__inline-items align-items-center">
                            <div className="col-8 schedule__event_left_pad0">
                              <TextField
                                className="material-form__field"
                                name={`${requirements}.${i}.${title}`}
                                label="Title"
                                placeholder="Title"
                                value={x?.title}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  setFieldValue(name, value);
                                  handleFormUpdateRequirements(
                                    rest,
                                    setFieldValue,
                                    "title",
                                    value,
                                    i
                                  );
                                }}
                                onFocus={(e) => {
                                  const { name } = e.target;
                                  handleFocusField(name);
                                }}
                                onBlur={() => handleBlurField()}
                              />
                              {focusField ===
                              `${requirements}.${i}.${title}` ? null : (
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${requirements}.${i}.${title}`}
                                />
                              )}
                            </div>

                            <div className="col-md-3 form__language">
                              <Tooltip title="Language" placement="bottom">
                                <div>
                                  {
                                    languages.find(
                                      (lang) =>
                                        lang.value === rest.values.form_language
                                    ).displayText
                                  }
                                </div>
                              </Tooltip>
                            </div>

                            <div className="col-1 schedule__event_right_pad0">
                              <Tooltip
                                title="Delete"
                                placement="bottom"
                                className="schedule__event_top_pad10"
                              >
                                <i
                                  className={`fa fa-trash icon-btn-action text-danger`}
                                  onClick={() =>
                                    handleRemoveClickRequirements(
                                      rest,
                                      setFieldValue,
                                      i
                                    )
                                  }
                                ></i>
                              </Tooltip>
                            </div>
                          </div>

                          <div className="form__inline-items">
                            <div className="col-8 schedule__event_left_pad0">
                              <div className="text-field-input">
                                <TextField
                                  className="material-form__field"
                                  name={`Description`}
                                  label={`Description`}
                                  placeholder={`Description`}
                                  type="text"
                                  onChange={(event) =>
                                    handleTextfieldChange(
                                      event,
                                      x?.description,
                                      setFieldValue,
                                      `${requirements}.${i}.${description}`,
                                      rest,
                                      i
                                    )
                                  }
                                  onKeyDown={(event) =>
                                    handleTextfieldChange(
                                      event,
                                      x?.description,
                                      setFieldValue,
                                      `${requirements}.${i}.${description}`,
                                      rest,
                                      i
                                    )
                                  }
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                />
                              </div>
                              {focusField ===
                              `${requirements}.${i}.${description}` ? null : (
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${requirements}.${i}.${description}`}
                                />
                              )}
                              {x?.description?.map((desc, j) => {
                                return (
                                  <div key={i + j}>
                                    <div className="form__inline-items">
                                      <span className="mt-1 mr-1">{`${
                                        j + 1
                                      }.`}</span>
                                      <div className="col-10 event_left_pad0">
                                        <TextField
                                          className="material-form__field"
                                          type="text"
                                          placeholder="Enter description here"
                                          name={`${requirements}.${i}.${description}.${j}`}
                                          value={desc}
                                          onChange={(e) => {
                                            handleDescriptionField(
                                              rest,
                                              setFieldValue,
                                              i,
                                              j,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="col-2 event_right_pad0">
                                        <Tooltip
                                          title="Delete Description"
                                          placement="bottom"
                                          className="event_top_pad10"
                                        >
                                          <i
                                            className={`fa fa-trash icon-btn-action text-danger`}
                                            onClick={() =>
                                              handleRemoveDescription(
                                                setFieldValue,
                                                desc,
                                                rest,
                                                i
                                              )
                                            }
                                          ></i>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    {focusField ===
                                    `${requirements}.${i}.${description}.${j}` ? null : (
                                      <ErrorMessage
                                        render={(msg) => (
                                          <div className="material-form__error">
                                            {msg}
                                          </div>
                                        )}
                                        name={`${requirements}.${i}.${description}.${j}`}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                            <div className="col-md-3 form__language mt-4">
                              <Tooltip title="Language" placement="bottom">
                                <div>
                                  {
                                    languages.find(
                                      (lang) =>
                                        lang.value === rest.values.form_language
                                    ).displayText
                                  }
                                </div>
                              </Tooltip>
                            </div>
                            <div className="col-1 schedule__event_right_pad0"></div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div className="col-6">
                <div className="section__container">
                  <div className="form__inline-items">
                    <div className="col-11 schedule__event_left_pad0">
                      <p className="bold-text">
                        Event Schedules ({rest.values.event_schedules.length})
                      </p>
                    </div>

                    <div className="col-1 schedule__event_right_pad0">
                      {(btn === "Add" ||
                        (btn === "Save" && fieldValues?.status === "Draft")) &&
                        rest.values.event_schedules.length === 0 && (
                          <Tooltip title="Add" placement="bottom" className="">
                            <i
                              className={`fa fa-plus icon-btn-action text-success`}
                              onClick={() =>
                                handleAddClickEventSchedules(
                                  rest,
                                  setFieldValue
                                )
                              }
                            ></i>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                  {rest?.values?.event_schedules.map((x, i) => {
                    return (
                      <div key={x + i} className="custom__div__dynamic__form">
                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0 bold-text pad__top15">
                            {i + 1}/{rest.values.event_schedules.length}
                          </div>
                          <div className="col-7 schedule__event_left_pad0">
                            <TextField
                              className="material-form__field"
                              name={`${event_schedules}.${i}.${title}`}
                              label="Title"
                              placeholder="Title"
                              value={x?.title[rest.values.form_language]}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setFieldValue(name, value);
                                handleFormUpdateEventSchedules(
                                  rest,
                                  setFieldValue,
                                  "title",
                                  value,
                                  i
                                );
                              }}
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                            />
                            {focusField ===
                            `${event_schedules}.${i}.${title}` ? null : (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${event_schedules}.${i}.${title}`}
                              />
                            )}
                          </div>

                          <div className="col-3 form__language mt-3">
                            <Tooltip title="Language" placement="bottom">
                              <div>
                                {
                                  languages.find(
                                    (lang) =>
                                      lang.value === rest.values.form_language
                                  ).displayText
                                }
                              </div>
                            </Tooltip>
                          </div>

                          <div className="col-1 schedule__event_right_pad0">
                            {(btn === "Add" ||
                              (btn === "Save" &&
                                fieldValues?.status === "Draft")) &&
                              (rest.values.event_schedules.length > 1
                                ? i === rest.values.event_schedules.length - 1
                                : i === 0) && (
                                <Tooltip
                                  title="Add"
                                  placement="bottom"
                                  className="pad__right5"
                                >
                                  <i
                                    className={`fa fa-plus icon-btn-action text-success`}
                                    onClick={() =>
                                      handleAddClickEventSchedules(
                                        rest,
                                        setFieldValue
                                      )
                                    }
                                  ></i>
                                </Tooltip>
                              )}
                            {(btn === "Add" ||
                              (btn === "Save" &&
                                fieldValues?.status === "Draft")) &&
                              (rest.values.event_schedules.length === 1
                                ? i > 0
                                : true) && (
                                <Tooltip
                                  title="Delete"
                                  placement="bottom"
                                  className="schedule__event_top_pad10"
                                  disabled={btn === "Save"}
                                >
                                  <i
                                    className={`fa fa-trash icon-btn-action text-danger`}
                                    onClick={() =>
                                      handleRemoveClickEventSchedules(
                                        rest,
                                        setFieldValue,
                                        i
                                      )
                                    }
                                  ></i>
                                </Tooltip>
                              )}
                          </div>
                        </div>
                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <div className="material-form_inline-fields">
                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${event_schedules}.${i}.type`}
                                  placeholder="Type"
                                  label="Select Type"
                                  select
                                  value={x?.type}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setFieldValue(name, value);
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "type",
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                  defaultValue={rest.values.type}
                                  disabled={
                                    btn === "Save" &&
                                    fieldValues.status !== "Draft"
                                  }
                                >
                                  {typeOptions.map((status) => (
                                    <MenuItem
                                      key={status.id}
                                      className="material-form__option"
                                      value={status.value}
                                    >
                                      {status.displayText}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {focusField ===
                                `${event_schedules}.${i}.type` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${event_schedules}.${i}.type`}
                                  />
                                )}
                              </div>

                              {isEdit && (
                                <div className="materila-form_inline-child">
                                  <TextField
                                    className="material-form__field"
                                    name={`${event_schedules}.${i}.booked`}
                                    placeholder="Booked Seats"
                                    label="Booked Seats"
                                    type="number"
                                    value={x?.booked || 0}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <div className="material-form_inline-fields">
                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${event_schedules}.${i}.attendees_limit`}
                                  placeholder="Optimum Batch Size"
                                  label="Optimum Batch Size"
                                  type="number"
                                  value={x?.attendees_limit}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setFieldValue(name, value);
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "attendees_limit",
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                  InputProps={{
                                    endAdornment: (
                                      <Tooltip
                                        title={fieldInfo.attendees_limit}
                                        placement="bottom"
                                      >
                                        <i className="fa fa-info-circle icon-file-info mt-1"></i>
                                      </Tooltip>
                                    ),
                                  }}
                                  disabled={x?.type === "Regular"}
                                />
                                {focusField ===
                                `${event_schedules}.${i}.attendees_limit` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${event_schedules}.${i}.attendees_limit`}
                                  />
                                )}
                              </div>
                              <div className="materila-form_inline-child">
                                <TextField
                                  className="material-form__field"
                                  name={`${event_schedules}.${i}.current_attendees_limit`}
                                  placeholder="Displayed Seats Count"
                                  label="Displayed Seats Count"
                                  type="number"
                                  value={x?.current_attendees_limit}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setFieldValue(name, value);
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "current_attendees_limit",
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                  InputProps={{
                                    endAdornment: (
                                      <Tooltip
                                        title={
                                          fieldInfo.current_attendees_limit
                                        }
                                        placement="bottom"
                                      >
                                        <i className="fa fa-info-circle icon-file-info mt-1"></i>
                                      </Tooltip>
                                    ),
                                  }}
                                  disabled={x?.type === "Regular" || isEdit}
                                />
                                {focusField ===
                                `${event_schedules}.${i}.current_attendees_limit` ? null : (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${event_schedules}.${i}.current_attendees_limit`}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <TextField
                              className="material-form__field"
                              name={`${event_schedules}.${i}.exceeds_percentage`}
                              placeholder="Threshold % for changing Displayed Seats Count"
                              label="Threshold % for changing Displayed Seats Count (in %)"
                              type="number"
                              value={x?.exceeds_percentage}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setFieldValue(name, value);
                                handleFormUpdateEventSchedules(
                                  rest,
                                  setFieldValue,
                                  "exceeds_percentage",
                                  value,
                                  i
                                );
                              }}
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                              disabled={x?.type === "Regular"}
                              InputProps={InputProps(
                                fieldInfo.exceeds_percentage
                              )}
                            />
                            {focusField ===
                            `${event_schedules}.${i}.exceeds_percentage` ? null : (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${event_schedules}.${i}.exceeds_percentage`}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <TextField
                              className="material-form__field"
                              name={`${event_schedules}.${i}.increment_percentage`}
                              placeholder="% for updating new Displayed Seats Count"
                              label="% for updating new Displayed Seats Count (in %)"
                              type="number"
                              value={x?.increment_percentage}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setFieldValue(name, value);
                                handleFormUpdateEventSchedules(
                                  rest,
                                  setFieldValue,
                                  "increment_percentage",
                                  value,
                                  i
                                );
                              }}
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                              disabled={x?.type === "Regular"}
                              InputProps={InputProps(
                                fieldInfo.increment_percentage
                              )}
                            />
                            {focusField ===
                            `${event_schedules}.${i}.increment_percentage` ? null : (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${event_schedules}.${i}.increment_percentage`}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <div className="material-form_inline-fields">
                              <div className="materila-form_inline-child">
                                <DateTimePicker
                                  selectedDate={
                                    (x?.starts_at && new Date(x?.starts_at)) ||
                                    ""
                                  }
                                  handleChange={(value) => {
                                    setFieldValue(
                                      `${event_schedules}.${i}.starts_at`,
                                      value
                                    );
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "starts_at",
                                      value,
                                      i
                                    );
                                  }}
                                  id="starts_at"
                                  name={`${event_schedules}.${i}.starts_at`}
                                  label="Starts At"
                                  customInput={
                                    <TextField
                                      className="material-form__field"
                                      id="starts_at"
                                      label="Starts At"
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${event_schedules}.${i}.starts_at`}
                                />
                              </div>

                              <div className="materila-form_inline-child">
                                <DateTimePicker
                                  selectedDate={
                                    (x?.ends_at && new Date(x?.ends_at)) || ""
                                  }
                                  handleChange={(value) => {
                                    setFieldValue(
                                      `${event_schedules}.${i}.ends_at`,
                                      value
                                    );
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "ends_at",
                                      value,
                                      i
                                    );
                                  }}
                                  id="ends_at"
                                  name={`${event_schedules}.${i}.ends_at`}
                                  label="Ends At"
                                  customInput={
                                    <TextField
                                      className="material-form__field"
                                      id="ends_at"
                                      label="Ends At"
                                      autoComplete="off"
                                    />
                                  }
                                />
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${event_schedules}.${i}.ends_at`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <div className="material-form_inline-fields">
                              {x?.type !== "Trial" ? (
                                <DateTimePicker
                                  selectedDate={
                                    (x?.allow_booking_post_commencement &&
                                      new Date(
                                        x?.allow_booking_post_commencement
                                      )) ||
                                    ""
                                  }
                                  handleChange={(value) => {
                                    setFieldValue(
                                      `${event_schedules}.${i}.allow_booking_post_commencement`,
                                      value
                                    );
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "allow_booking_post_commencement",
                                      value,
                                      i
                                    );
                                  }}
                                  id="allow_booking_post_commencement"
                                  name={allow_booking_post_commencement}
                                  label="Last Date and Time to book seats"
                                  disabled
                                  customInput={
                                    <TextField
                                      className="material-form__field"
                                      id="allow_booking_post_commencement"
                                      label="Last Date and Time to book seats"
                                      autoComplete="off"
                                      disabled
                                      InputProps={InputProps(
                                        fieldInfo.allow_booking_post_commencement
                                      )}
                                    />
                                  }
                                />
                              ) : (
                                <TextField
                                  className="material-form__field"
                                  name={`${event_schedules}.${i}.allow_booking_post_commencement`}
                                  placeholder="Allow Booking till"
                                  label="Allow Booking till (in minutes)"
                                  type="number"
                                  value={x?.allow_booking_post_commencement}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setFieldValue(name, value);
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "allow_booking_post_commencement",
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                  disabled={x?.type === "Regular"}
                                  InputProps={InputProps(
                                    fieldInfo.allow_booking_till
                                  )}
                                />
                              )}
                              {x?.type === "Trial" && (
                                <div>
                                  {x?.allow_booking_post_commencement &&
                                  x?.starts_at ? (
                                    <div className="commencement__date">
                                      {moment(x?.starts_at)
                                        .add(
                                          x?.allow_booking_post_commencement,
                                          "minutes"
                                        )
                                        .format("DD/MM/YYYY, hh:mm A ")}
                                    </div>
                                  ) : (
                                    <div className="commencement__date">
                                      mm/dd/yyyy, <br />
                                      --:--
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {x?.type !== "Trial" && (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${event_schedules}.${i}.allow_booking_post_commencement`}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <div className="material-form_inline-fields">
                              {x?.type !== "Trial" ? (
                                <DateTimePicker
                                  selectedDate={
                                    (x?.allow_seats_increment &&
                                      new Date(x?.allow_seats_increment)) ||
                                    ""
                                  }
                                  handleChange={(value) => {
                                    setFieldValue(
                                      `${event_schedules}.${i}.allow_seats_increment`,
                                      value
                                    );
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "allow_seats_increment",
                                      value,
                                      i
                                    );
                                  }}
                                  id="allow_booking_post_commencement"
                                  name={allow_seats_increment}
                                  label="Last Date and Time to Increase Displayed Seat Count"
                                  disabled
                                  customInput={
                                    <TextField
                                      className="material-form__field"
                                      id="allow_seats_increment"
                                      label="Last Date and Time to Increase Displayed Seat Count"
                                      autoComplete="off"
                                      disabled
                                      InputProps={InputProps(
                                        fieldInfo.allow_seats_increment
                                      )}
                                    />
                                  }
                                />
                              ) : (
                                <TextField
                                  className="material-form__field"
                                  name={`${event_schedules}.${i}.allow_seats_increment`}
                                  placeholder="Seat Increment Till"
                                  label="Seat Increment Till (in minutes)"
                                  type="number"
                                  value={x?.allow_seats_increment}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    setFieldValue(name, value);
                                    handleFormUpdateEventSchedules(
                                      rest,
                                      setFieldValue,
                                      "allow_seats_increment",
                                      value,
                                      i
                                    );
                                  }}
                                  onFocus={(e) => {
                                    const { name } = e.target;
                                    handleFocusField(name);
                                  }}
                                  onBlur={() => handleBlurField()}
                                  disabled={x?.type === "Regular"}
                                  InputProps={InputProps(
                                    fieldInfo.seat_increment_till
                                  )}
                                />
                              )}
                              {x?.type === "Trial" && (
                                <div>
                                  {x?.allow_seats_increment && x?.starts_at ? (
                                    <div className="commencement__date">
                                      {moment(x?.starts_at)
                                        .add(
                                          x?.allow_seats_increment,
                                          "minutes"
                                        )
                                        .format("DD/MM/YYYY, hh:mm A ")}
                                    </div>
                                  ) : (
                                    <div className="commencement__date">
                                      mm/dd/yyyy, <br />
                                      --:--
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                            {x?.type === "Trial" && (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${event_schedules}.${i}.allow_seats_increment`}
                              />
                            )}
                          </div>
                        </div>

                        <div key={x + i} className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <TextField
                              className="material-form__field"
                              name={`${event_schedules}.${i}.${pre_event_file_cta}`}
                              label="Pre Event File CTA"
                              placeholder="Pre Event File CTA"
                              value={x?.pre_event_file_cta}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setFieldValue(name, value);
                                handleFormUpdateEventSchedules(
                                  rest,
                                  setFieldValue,
                                  pre_event_file_cta,
                                  value,
                                  i
                                );
                              }}
                              onFocus={(e) => {
                                const { name } = e.target;
                                handleFocusField(name);
                              }}
                              onBlur={() => handleBlurField()}
                            />
                            {focusField ===
                            `${event_schedules}.${i}.${pre_event_file_cta}` ? null : (
                              <ErrorMessage
                                render={(msg) => (
                                  <div className="material-form__error">
                                    {msg}
                                  </div>
                                )}
                                name={`${event_schedules}.${i}.${pre_event_file_cta}`}
                              />
                            )}
                          </div>

                          <div className="col-1 schedule__event_right_pad0"></div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-8 schedule__event_left_pad0">
                            <div>
                              <TextfieldChips
                                initialChips={
                                  x?.chapters[rest.values.form_language] || []
                                }
                                name="Chapters"
                                onDataChange={(data) => {
                                  setFieldValue(
                                    `${event_schedules}.${i}.chapters`,
                                    data
                                  );
                                  handleFormUpdateEventSchedules(
                                    rest,
                                    setFieldValue,
                                    "chapters",
                                    data,
                                    i
                                  );
                                }}
                              />
                              {focusField ===
                              `${event_schedules}.${i}.chapters` ? null : (
                                <ErrorMessage
                                  render={(msg) => (
                                    <div className="material-form__error">
                                      {msg}
                                    </div>
                                  )}
                                  name={`${event_schedules}.${i}.chapters`}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-3 schedule__event_right_pad0 form__language mt-3">
                            <Tooltip title="Language" placement="bottom">
                              <div>
                                {
                                  languages.find(
                                    (lang) =>
                                      lang.value === rest.values.form_language
                                  ).displayText
                                }
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="form__inline-items">
                          <div className="col-1 schedule__event_left_pad0">
                            {" "}
                          </div>
                          <div className="col-10 schedule__event_left_pad0">
                            <div className="form__form-group-field mt-1">
                              <div className="form__form-group-input-wrap">
                                <div className="form__form-group-file">
                                  <label htmlFor={`${pre_event_file}${i}`}>
                                    Upload Pre Event File
                                  </label>
                                  <span>
                                    {files
                                      .filter(
                                        (file) =>
                                          file.file_context ===
                                          `${fileContext.EVENT_ATTACHMENT}_${i}`
                                      )
                                      .map((x) => {
                                        return (
                                          <Chip
                                            className="selected-file"
                                            key={x.uuid_file_name}
                                            label={x.original_file_name}
                                            onDelete={() =>
                                              handleDeleteAFile(x)
                                            }
                                            variant="outlined"
                                          />
                                        );
                                      })}

                                    {isFileUploadData
                                      .filter(
                                        (file) =>
                                          file.fileContext ===
                                          `${fileContext.EVENT_ATTACHMENT}_${i}`
                                      )
                                      .map((x, i) => {
                                        return (
                                          <>
                                            {x.isUpload && (
                                              <ProgressChip
                                                fileName={x.fileName}
                                                uploadProgress={uploadProgress}
                                              />
                                            )}
                                          </>
                                        );
                                      })}
                                  </span>
                                  <input
                                    accept="application/pdf"
                                    onBlur={rest.handleBlur}
                                    type="file"
                                    name={`${event_schedules}.${i}.${pre_event_file}`}
                                    id={`${pre_event_file}${i}`}
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                    onChange={(event) => {
                                      handleAttachmentFileCourse(
                                        event.currentTarget.files[0],
                                        i
                                      );
                                      setFieldValue(
                                        `${event_schedules}.${i}.${pre_event_file}`,
                                        files.filter(
                                          (file) =>
                                            file.file_context ===
                                            `${fileContext.EVENT_ATTACHMENT}_${i}`
                                        )
                                      );
                                    }}
                                  />
                                </div>
                                {`${rest.errors}.${event_schedules}.${i}.${pre_event_file}` ? (
                                  <ErrorMessage
                                    render={(msg) => (
                                      <div className="material-form__error mt-1">
                                        {msg}
                                      </div>
                                    )}
                                    name={`${event_schedules}.${i}.${pre_event_file}`}
                                  />
                                ) : (
                                  <div className="material-form__error">
                                    {uploadError}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-1 schedule__event_right_pad0"></div>
                        </div>

                        {btn === "Save" &&
                          formValues.status === "Published" &&
                          moment(
                            formValues?.event_schedules[i]?.ends_at
                          ).isBefore(new Date()) && (
                            <div className="form__inline-items">
                              <div className="col-1 schedule__event_left_pad0">
                                {" "}
                              </div>
                              <div className="col-10 schedule__event_left_pad0">
                                <div className="form__form-group-field mt-1">
                                  <div className="form__form-group-input-wrap">
                                    <div className="form__form-group-file">
                                      <label htmlFor={`${note}${i}`}>
                                        Upload Note
                                      </label>
                                      <span>
                                        {files
                                          .filter(
                                            (file) =>
                                              file.file_context ===
                                              `${fileContext.NOTE}_${i}`
                                          )
                                          .map((x) => {
                                            return (
                                              <Chip
                                                className="selected-file"
                                                key={x.uuid_file_name}
                                                label={x.original_file_name}
                                                onDelete={() =>
                                                  handleDeleteAFile(x)
                                                }
                                                variant="outlined"
                                              />
                                            );
                                          })}

                                        {isFileUploadData
                                          .filter(
                                            (file) =>
                                              file.fileContext ===
                                              `${fileContext.NOTE}_${i}`
                                          )
                                          .map((x, i) => {
                                            return (
                                              <>
                                                {x.isUpload && (
                                                  <ProgressChip
                                                    fileName={x.fileName}
                                                    uploadProgress={
                                                      uploadProgress
                                                    }
                                                  />
                                                )}
                                              </>
                                            );
                                          })}
                                      </span>
                                      <input
                                        accept="application/pdf"
                                        onBlur={rest.handleBlur}
                                        type="file"
                                        name={`${event_schedules}.${i}.${note}`}
                                        id={`${note}${i}`}
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                        onChange={(event) => {
                                          handleAttachmentCourseNote(
                                            event.currentTarget.files[0],
                                            i
                                          );
                                          setFieldValue(
                                            `${event_schedules}.${i}.${note}`,
                                            files.filter(
                                              (file) =>
                                                file.file_context ===
                                                `${fileContext.NOTE}_${i}`
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                    {`${rest.errors}.${event_schedules}.${i}.${note}` ? (
                                      <ErrorMessage
                                        render={(msg) => (
                                          <div className="material-form__error mt-1">
                                            {msg}
                                          </div>
                                        )}
                                        name={`${event_schedules}.${i}.${note}`}
                                      />
                                    ) : (
                                      <div className="material-form__error">
                                        {uploadError}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-1 schedule__event_right_pad0"></div>
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-success btn-right btn-sm">
              Submit
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default CourseForm;

export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 70,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];

export const productStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const fieldsName = {
  title: "title",
  status: "status",
  about: "about",
  fileIcon: "fileIcon",
  fileBanner: "fileBanner",
  categoryId: "categoryId",
  brandId: "brandId",
};

export const categoryParams = {
  status: "Published",
  type: "Product",
};

export const brandsListParams = {
  status: "Published",
  limit: 1000,
  skip: 0,
};

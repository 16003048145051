import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";
import { setFiles } from "../../../../utils/upload-file/actions";

const namespace = "containers/offers/brands";

export const SET_BRANDS_LIST = `${namespace}/SET_BRANDS_LIST`;
export const SET_BRANDS_FILTER = `${namespace}/SET_BRANDS_FILTER`;
export const RESET_BRANDS_FILTER = `${namespace}/RESET_BRANDS_FILTER`;

const setBrandsList = (payload) => ({
  type: SET_BRANDS_LIST,
  payload,
});

export const setBrandsFilter = (payload) => ({
  type: SET_BRANDS_FILTER,
  payload,
});

export const resetBrandsFilter = (payload) => ({
  type: RESET_BRANDS_FILTER,
  payload,
});

export const getBrandsList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.brands, {
      params: { params },
    });
    const { data = {} } = response;
    if (data?.brands) {
      dispatch(setBrandsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addBrand = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addBrand, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Partner not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleBrand = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getBrandDetails(id));
    const { data: { is_success, brand,logo } = {} } = response;
    if (is_success) {
      dispatch(setFiles(logo));
      return brand;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editBrand = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateBrandDetail(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

import React from 'react'
import { Card, CardBody, Col } from "reactstrap";
import MagazineForm from "../form/magazine-form";
import { useAddMagazine } from './hooks';

const AddMagazine = (props) => {

  const {
    handleAddMagazine,
    addMagazineLoading,
    magazines,
    setSearchText,
  } = useAddMagazine(props);

  return (
    <div>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper">
              <div className="card__title card__title__flex">
                <h3 className="bold-text">ADD MAGAZINE</h3>
              </div>
            </div>
            <MagazineForm
              handleMagazineForm={handleAddMagazine}
              btn="Add"
              magazines={magazines}
              isLoading={addMagazineLoading}
              setSearchText={setSearchText}
            />
          </CardBody>
        </Card>
      </Col>
    </div>
  )
}

export default AddMagazine

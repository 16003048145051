import * as Yup from "yup";

export const blogFormSchema = Yup.object().shape({
 
  label_id: Yup.string().nullable(),
  status: Yup.string().required("Status is required").nullable(),
  fileBanner: Yup.array().required("File Banner is required"),
  fileBannerTile: Yup.array(),
  title: Yup.string().required(" Title is required"),
  published_at: Yup.date().required("Published At is required"),
});

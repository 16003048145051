import React from "react";
import CreditForm from "../form/credit-form";
import { useAddCredit } from "./hooks";
import Modals from "../../../../components/modal";
import { Button } from "reactstrap";
import { useUser } from "../../../../common-hooks/user";


const AddCredit = ({ user_id }) => {
  const {
    handleAddCredit,
    isOpenModal,
    toggleModal,
    addUserLoading,
  } = useAddCredit({ user_id });

  const { isAdmin, isCSSpecialist } = useUser();

  if (!isAdmin && !isCSSpecialist) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={() => {
          toggleModal(!isOpenModal);
        }}
      >
        <i className="fa fa-plus"></i> Add Credit
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <CreditForm
              handleBonusCreditForm={handleAddCredit}
              btn="Add"
              isLoading={addUserLoading}
            />
          }
          title="Add Credit"
        />
      ) : null}
    </div>
  );
};

export default AddCredit;

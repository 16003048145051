export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Event",
    accessor: "title",
    disableGlobalFilter: true,
  },
  {
    Header: "Type",
    accessor: "is_free",
    disableGlobalFilter: true,
  },
  {
    Header: "Starts At",
    accessor: "starts_at",
  },
  {
    Header: "Ends At",
    accessor: "ends_at",
    disableGlobalFilter: true,
  },
  {
    Header: "Mode",
    accessor: "mode",
    disableGlobalFilter: true,
  },
  {
    Header: "Event Type",
    accessor: "eventType",
    disableGlobalFilter: true,
  },
  {
    Header: "Language",
    accessor: "language",
    disableGlobalFilter: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
  },
  {
    Header: "Optimum Batch size",
    accessor: "attendees_limit",
    disableGlobalFilter: true,
  },

  {
    Header: "Displayed Seats count",
    accessor: "current_attendees_limit",
    disableGlobalFilter: true,
  },
  {
    Header: "Booked Seats",
    accessor: "booked",
    disableGlobalFilter: true,
  },

  {
    Header: "Allow Booking Till",
    accessor: "allow_booking_post_commencement",
    disableGlobalFilter: true,
  },
  {
    Header: "Seat Increment Till",
    accessor: "allow_seats_increment",
    disableGlobalFilter: true,
  },
  {
    Header: "Host Name",
    accessor: "hostName",
    disableGlobalFilter: true,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
  },
];

export const scheduleEventStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const scheduleEventAccessTypes = [
  {
    id: 1,
    value: "Public",
    displayText: "Public",
  },
  {
    id: 2,
    value: "Private",
    displayText: "Private",
  },
];

export const scheduleEventExportStatus = [
  {
    id: 1,
    value: "All",
    displayText: "All",
  },
  {
    id: 2,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 3,
    value: "Draft",
    displayText: "Draft",
  },
  {
    id: 4,
    value: "Cancelled",
    displayText: "Cancelled",
  },
];

export const scheduleEventReviewStatus = {
  Published: "Published",
  Unpublished: "Unpublished",
};

export const scheduleEventRepeat = [
  {
    id: 1,
    value: "Once",
    displayText: "Once",
  },
];

export const scheduleEventLanguage = [
  {
    id: 1,
    value: "Hindi",
    displayText: "Hindi",
  },
  {
    id: 2,
    value: "English",
    displayText: "English",
  },
  {
    id: 3,
    value: "Gujarati",
    displayText: "Gujarati",
  },
  {
    id: 4,
    value: "Marathi",
    displayText: "Marathi",
  },
  {
    id: 5,
    value: "Tamil",
    displayText: "Tamil",
  },
];

export const scheduleEventMode = [
  {
    id: 1,
    value: "Online",
    displayText: "Online",
  },
  {
    id: 2,
    value: "Offline",
    displayText: "Offline",
  },
];

export const eventTypes = [
  {
    id: 1,
    value: "Free",
    displayText: "Free",
  },
  {
    id: 2,
    value: "Paid",
    displayText: "Paid",
  },
];

export const fieldsName = {
  no_of_credits: "no_of_credits",
  startsAt: "startsAt",
  endsAt: "endsAt",
  status: "status",
  repeat: "repeat",
  language: "language",
  mode: "mode",
  booked: "booked",
  attendeesLimit: "attendeesLimit",
  current_attendees_limit: "current_attendees_limit",
  exceeds_percentage: "exceeds_percentage",
  increment_percentage: "increment_percentage",
  allow_booking_post_commencement: "allow_booking_post_commencement",
  allow_seats_increment: "allow_seats_increment",
  eventId: "event_id",
  hostIds: "hostIds",
  cohostIds: "cohostIds",
  requirements: "requirements",
  title: "title",
  description: "description",
  is_attachment: "is_attachment",
  cta_title: "cta_title",
  attachment_file: "attachment_file",
  type: "type",
  languages: "languages",
  eventType: "eventType",
  allow_discussions: "allow_discussions",
  broadcast_mins: "broadcast_mins",
  after_popup_broadcast_mins: "after_popup_broadcast_mins",
  max_join_broadcast_attempts: "max_join_broadcast_attempts",
  streaming_type: "streaming_type",
  streaming_platform: "streaming_platform",
  dates: "dates",
  waiting_room_start_time:"waiting_room_start_time"
};

export const NotesUploadFieldsName = {
  file: "file",
  isUploading: "isUploading",
};

export const ExportFieldsName = {
  startsAt: "startsAt",
  endsAt: "endsAt",
  status: "status",
};

export const postByList = [
  {
    id: 1,
    value: "Me",
    displayText: "Me",
  },
  {
    id: 2,
    value: "EvergreenClub",
    displayText: "Evergreen Club",
  },
];
export const fieldInfo = {
  attendees_limit: "Batch size as per Moderator/ Instructor",
  current_attendees_limit:
    "Count of seats available - displayed to user on App/ Web",
  exceeds_percentage: `When booked seats is XX % of displayed seats, then displayed seats count will be updated`,
  increment_percentage: `% of (optimum batch count - displayed seat count)`,
  allow_booking_till:
    "User can book event only till Nth minute within the session",
  seat_increment_till:
    "Moderator can increase seats only till Zth minute of the session",
  broadcast_mins:
    "User can view live session for the minutes defined. Post this time, user will see the next step",
  after_popup_broadcast_mins:
    "User can re-view live session for the minutes defined. Post this time, user will see the next step ",
  max_join_broadcast_attempts:
    "User cannot see sign up pop-up after he has viewed it for this count of times",
};

export const postsFilterStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const egTaggedFilter = [
  {
    id: 1,
    value: "All",
    displayText: "All Discussions",
  },
  {
    id: 2,
    value: "Yes",
    displayText: "EG Tagged Discussions",
  },
];

export const userRoles = [
  {
    id: 1,
    value: "Admin",
    displayText: "Admin",
  },
  {
    id: 2,
    value: "Member",
    displayText: "Member",
  },
  {
    id: 3,
    value: "Coordinator",
    displayText: "Coordinator",
  },
  {
    id: 4,
    value: "Moderator",
    displayText: "Moderator",
  },
  {
    id: 5,
    value: "Customer Support Specialist",
    displayText: "Customer Support Specialist",
  },
];

export const eventTimings = {
  minTime: 15, // this is in minutes
  maxTime: 6, // this is in hours
};

export const eventDatesLimit = 90;

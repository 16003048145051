import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Magazines from '../../../CMS/magazines';
import AddMagazine from '../../../CMS/magazines/add-magazine';
import EditMagazine from '../../../CMS/magazines/edit-magazine';


export default () => (
  <Switch>
    <Route path="/magazines" component={Magazines} />
    <Route path="/add-magazine" component={AddMagazine} />
    <Route path="/edit-magazine/:magazine_id" component={EditMagazine} />
  </Switch>
);

/* eslint-disable */

import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import {
  EditorState,
  convertToRaw,
  genKey,
  convertFromRaw,
  ContentState,
  Modifier,
} from "draft-js";
import { MENTION_TYPES } from "../../constant";
import { getHashtags, getUserList } from "../../action";
import { trackableTypes } from "../../../../../constants";
import {
  _addPastedContent,
  _getLengthOfSelectedText,
  _removeSelection,
} from "../../../utils";

const lazzyType = lodash.debounce((value, callback) => {
  callback(value);
}, 50);

export const useCaptionInput = (props) => {
  const { trackableType, singleLine } = props;
  const [searchUserWord, setSearchUserWord] = useState("");
  const [searchHashtagWord, setSearchHashtagWord] = useState("");
  const [users, setUsers] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const mentionsRef = useRef();
  const [editorState, setEditorState] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.clear && props.onClearSuccess) {
      const clearEditorState = EditorState.push(
        editorState,
        ContentState.createFromText(""),
        "remove-range"
      );
      setEditorState(clearEditorState);
      props.onClearSuccess();
    }

    return () => props.onClearSuccess && props.onClearSuccess();
  }, [props.clear]);

  useEffect(() => {
    if (!!props.caption) {
      const editorStateInitial = {
        blocks: [
          {
            key: genKey(),
            text: props.caption.replaceAll("\u0001", "\n"),
            type: "unstyled",
            inlineStyleRanges: [],
            data: {},
            depth: 0,
            entityRanges: [],
          },
        ],
        entityMap: {},
      };
      if (props?.mentions?.length > 0) {
        const entityRanges = [];
        const entityMap = {};
        props?.mentions.forEach((mention, index) => {
          entityRanges.push({
            offset: mention.offset,
            length: mention.length,
            key: index,
          });
          entityMap[`${index}`] = {
            type:
              mention.trackable_type === MENTION_TYPES.hashtag
                ? "#mention"
                : "mention",
            mutability: "SEGMENTED",
            data: {
              mention: {
                id: mention.trackable_id,
                name: `${mention.trackable_type === MENTION_TYPES.hashtag ? "#" : "@"
                  }${mention.name}`,
                user_id: mention.trackable_id,
              },
            },
          };
        });
        editorStateInitial.blocks[0].entityRanges = entityRanges;
        editorStateInitial.entityMap = entityMap;
      }
      setEditorState(
        EditorState.createWithContent(convertFromRaw(editorStateInitial))
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [props.caption]);

  useEffect(() => {
    if (mentionsRef.current) {
      mentionsRef.current.focus();
    }
  }, [mentionsRef.current]);

  useEffect(() => {
    (async () => {
      if (trackableType === trackableTypes.DISCUSSION) {
        setUsers([]);
      } else {
        const userList = await dispatch(
          getUserList({
            limit: 10,
            skip: 0,
            text: searchUserWord,
            status: { is_active: true },
          })
        );

        const list = userList.map((user) => ({
          ...user,
          name: `@${user?.name}`,
        }));

        if (props.isAttendee) {
          setUsers(list);
        } else {
          setUsers(list);
        }
      }
    })();
  }, [searchUserWord, dispatch, props.isAttendee]);

  useEffect(() => {
    (async () => {
      if (trackableType === trackableTypes.DISCUSSION) {
        setHashtags([]);
      } else {
        const tags = await dispatch(
          getHashtags({ tag: searchHashtagWord, limit: 10, skip: 0 })
        );

        const hashtagList = tags?.hashtags?.map((tag, index) => ({
          ...tag,
          name: `#${tag?.hashtag_title}`,
          count: tag?.posts_count,
        }));
        setHashtags(hashtagList);
      }
    })();
  }, [searchHashtagWord, dispatch]);

  const handleMentionChange = useCallback(
    lodash.debounce(({ value }) => {
      setSearchUserWord(value);
    }, 300),
    []
  );

  const handleHashtagChange = useCallback(
    lodash.debounce(({ value }) => {
      setSearchHashtagWord(value);
    }, 300),
    []
  );

  const handleCaptionChange = (editorStateDetail) => {
    lazzyType(editorStateDetail, (editorStateValue) => {
      const contentState = editorStateValue.getCurrentContent();
      const raw = convertToRaw(contentState);
      let plainText = editorStateValue
        .getCurrentContent()
        .getPlainText("\u0001");
      const entityMap = Object.values(raw.entityMap);
      const [block = {}] = raw.blocks || [];
      const ranges = [];
      block?.entityRanges?.forEach((range, index) => {
        const entityMapDetail = entityMap[index];
        if (
          entityMapDetail.type === "mention" ||
          entityMapDetail.type.includes("#")
        ) {
          let rangeObj = {
            ...range,
            ...entityMapDetail?.data?.mention,
            name:
              entityMapDetail?.data?.mention?.name?.slice(0, range.length) ||
              "",
            type: entityMapDetail?.type?.includes("#") ? "#" : "@",
          };

          rangeObj.offset = range.offset;
          rangeObj.length = range.length;
          ranges.push(rangeObj);
        }
      });
      props.onCaptionChange(ranges, plainText);
    });
    setEditorState(editorStateDetail);
  };

  const handlePastedText = (pastedText) => {
    const selection = editorState.getSelection();
    const isCollapsed = selection.isCollapsed();
    const removeSelectionState = _removeSelection(editorState);
    if (!isCollapsed) {
      setEditorState(removeSelectionState);
    }
    const tempEditorState = !isCollapsed ? removeSelectionState : editorState;
    const addPastedState = _addPastedContent(pastedText, tempEditorState);
    setEditorState(addPastedState);

    return "handled";
  };

  return {
    users,
    hashtags,
    handleCaptionChange,
    mentionsRef,
    handleMentionChange,
    handleHashtagChange,
    editorState,
    trackableType,
    singleLine,
    handlePastedText,
  };
};

import React, {  useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, Chip, MenuItem, Tooltip } from "@material-ui/core";
import { fieldNames, statusOptions } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { blogFormSchema } from "./validation-schema";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import {
  fileContext,
  imageDimensions,
} from "../../../../../constants";
import DatePicker from "../../../../../components/date-picker";
import ProgressChip from "../../../../../components/progress-chip";
import Croppier from "../../../../../components/image-croppier";

const formField = {
  title: "",
  status: "",
  label_id: "",
  fileBanner: "",
  fileBannerTile: "",
  published_at: "",
  redirection_link: ""
};

const BlogForm = ({
  fieldValues,
  handleBlogForm,
  btn,
  isLoading,
  labels,
  blogs,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const {
    uploadError,
    fileBannerError,
    handleDeleteAFile,
    files,
    handleBannerFile,
    uploadProgress,
    isFileUploadData,
    handleCropping,
  } = useUploadFiles();

  const {
    title ,
    status ,
    label_id,
    fileBanner,
    published_at,
    redirection_link,
  } = fieldNames;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);
  const [blogBanner, setBlogBanner] = useState([]);
  const { seniors_today_blog_banner } = imageDimensions;

  const handleFormUpdate = async (key, value) => {
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [key]: value,
    }));
  };


  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={blogFormSchema}
      initialValues={{
        title: formValues?.title || "",       
        label_id:
          (formValues?.label_id &&
            labels.find((label) => label.id === formValues?.label_id.id)) ||
          (formValues?.label_id &&
            labels.find((label) => label.id === formValues?.label_id)) ||
          null,
        published_at:
          (formValues?.published_at && new Date(formValues.published_at)) || "",
        status: formValues?.status || "",
        fileBanner: files.filter(
          (file) => file.file_context === fileContext.BANNER
        ),
        fileBannerTile: files.filter(
          (file) => file.file_context === fileContext.BANNER_TILE
        ),
        redirection_link: formValues?.redirection_link || "",
      }}
      onSubmit={handleBlogForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={title}
                placeholder="Title"
                label="Title"
                type="text"
                value={rest.values.title}
                onChange={(event) => {
                  handleFormValueChange(rest, title);
                  handleFormUpdate(title, event.target.value);
                }}
                onFocus={() => handleFocusField(title)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={title}
                />
              )}
              </div>
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={redirection_link}
                placeholder="Blog Link"
                label="Blog Link"
                type="text"
                value={rest.values.redirection_link}
                onChange={(event) => {
                  handleFormValueChange(rest, redirection_link);
                  handleFormUpdate(redirection_link, event.target.value);
                }}
                onFocus={() => handleFocusField(redirection_link)}
                onBlur={() => handleBlurField()}
              />
              {focusField === title ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={redirection_link}
                />
              )}
            </div>


            


            <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={status}
                  placeholder="Status"
                  label="Select Status"
                  select
                  value={rest.values.status}
                  onChange={(event) => {
                    handleFormValueChange(rest, status);
                    handleFormUpdate(status, event.target.value);
                  }}
                  onFocus={() => handleFocusField(status)}
                  onBlur={() => handleBlurField()}
                  defaultValue={rest.values.status}
                >
                  {statusOptions.map((status) => (
                    <MenuItem
                      key={status.id}
                      className="material-form__option"
                      value={status.value}
                    >
                      {status.displayText}
                    </MenuItem>
                  ))}
                </TextField>
                {focusField === status ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={status}
                  />
                )}
              </div>
          
            

              <div className="materila-form_inline-child">
                <Autocomplete
                  options={labels}
                  getOptionLabel={(label) => (label.title ? label.title : "")}
                  value={rest.values.label_id}
                  name={label_id}
                  onChange={(event, value) => {
                    setFieldValue(label_id, value);
                    handleFormUpdate(label_id, value);
                  }}
                  onFocus={() => handleFocusField(label_id)}
                  onBlur={() => handleBlurField()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="material-form__field"
                      name={label_id}
                      placeholder="Select Label"
                      label="Select Label"
                      variant="standard"
                    />
                  )}
                />
                {focusField === label_id ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={label_id}
                  />
                )}
              </div>

            <div className="matrial-form material-form_inline-fields">
              <div className="materila-form_inline-child">
                <DatePicker
                  selectedDate={rest.values.published_at}
                  handleChange={(value) => {
                    setFieldValue(published_at, value);
                    handleFormUpdate(published_at, value);
                  }}
                  id="published_at"
                  name={published_at}
                  label="Published At"
                  customInput={
                    <TextField
                      className="material-form__field"
                      id="published_at"
                      label="Published At"
                      autoComplete="off"
                    />
                  }
                />
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={published_at}
                />
              </div>
              <div className="materila-form_inline-child"></div>
              <div className="materila-form_inline-child"></div>
            </div>


            <div className="blogs__container">
              <p className="custom__title_blogs">Banner</p>
              <div className="form__form-group-field form__margin-bottom">
                <div className="form__form-group-input-wrap">
                  <div className="form__form-group-file">
                    <label htmlFor="fileBanner">Choose Banner</label>
                    <Tooltip
                      title="Preferred size is 350px * 500px"
                      placement="bottom"
                    >
                      <i className="fa fa-info-circle icon-file-info mt-1"></i>
                    </Tooltip>
                    <br />
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}
                    {isFileUploadData
                      .filter((file) => file.fileContext === fileContext.BANNER)
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                    <input
                      accept="image/*"
                      multiple
                      type="file"
                      name={fileBanner}
                      id="fileBanner"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      onChange={(event) => {
                        handleCropping(
                          event.currentTarget.files,
                          setFieldValue,
                          fileBanner,
                          fileContext.BANNER,
                          handleBannerFile,
                          seniors_today_blog_banner,
                          setBlogBanner
                        );
                        // handleBannerFile(event.currentTarget.files);
                        // setFieldValue(
                        //   fileBanner,
                        //   files.filter(
                        //     (file) => file.file_context === fileContext.BANNER
                        //   )
                        // );
                      }}
                    />
                  </div>

                  {fileBannerError || uploadError ? (
                    <div className="material-form-error-image">
                      {fileBannerError || uploadError}
                    </div>
                  ) : (
                    <ErrorMessage
                      render={(msg) => (
                        <div className="material-form-error-image">{msg}</div>
                      )}
                      name={fileBanner}
                    />
                  )}
                </div>
              </div>

              <div className="blog_croppier" style={{maxHeight:"50%", maxWidth:"30%"}}>
              {blogBanner?.length > 0 &&
                blogBanner?.map((banner, i) => (
                  <div key={i}>
                    <Croppier
                      image={banner}
                      setFieldValue={setFieldValue}
                      name={fileBanner}
                      context={fileContext.BANNER}
                      dimensions={seniors_today_blog_banner}
                      handleUploadFile={handleBannerFile}
                      setCurrentBanner={setBlogBanner}
                      index={i}
                      
                    />
                  </div>
                ))}
            </div>
            </div>

           
            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BlogForm;

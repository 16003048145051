import React from "react";
import { Button } from "reactstrap";

import { useAddBrand } from "./hooks";
import Modals from "../../../../../components/modal";
import { useUser } from "../../../../../common-hooks/user";
import BrandForm from "../form/brand-form";

const AddBrand = (props) => {
  const { addBrandLoading, isOpenModal, toggleModal, handleAddBrand } =
    useAddBrand(props);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Button
        className="btn btn-success btn-right btn-sm"
        onClick={toggleModal}
      >
        <i className="fa fa-plus"></i> Add Brand
      </Button>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <BrandForm
              handleBrandForm={handleAddBrand}
              btn="Add"
              isLoading={addBrandLoading}
            />
          }
          title="Add Brand"
        />
      ) : null}
    </div>
  );
};

export default AddBrand;

import { SET_CATEGORY_LIST, SET_PRODUCTS_FILTER, SET_PRODUCTS_LIST } from "./actions";

const initialList = {
  products: [],
  categories: [],
  count: 0,
  filter: {
    limit: 10,
    skip: 0,
  },
};

const productReducer = (state = initialList, action) => {
  switch (action.type) {
    case SET_PRODUCTS_LIST:
      return {
        ...state,
        products: action.payload.products,
        count: action.payload.count || [],
      };

    case SET_CATEGORY_LIST:
      return { ...state, categories: action.payload };
    case SET_PRODUCTS_FILTER: {
      const { key, value } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          [key]: value,
        },
      };
    }
    default:
      return state;
  }
};

export default productReducer;

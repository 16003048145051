export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
    disableGlobalFilter: true,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Product Name",
    accessor: "product.name",
  },
  {
    Header: "Status",
    accessor: "status",
    disableGlobalFilter: true,
    width: 70,
  },
  {
    Header: "Actions",
    accessor: "action",
    disableGlobalFilter: true,
    width: 60,
  },
];
export const offerType = [
  {
    id: 1,
    value: "Coupon",
    displayText: "Coupon",
  },
  {
    id: 2,
    value: "Offer",
    displayText: "Offer",
  },
];

export const offerStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Draft",
    displayText: "Draft",
  },
];

export const fieldsName = {
  title: "title",
  type: "type",
  subTitle: "subTitle",
  code: "code",
  validity: "validity",
  productId: "productId",
  details: "details",
  note: "note",
  redirectLink: "redirectLink",
  status: "status",
  banner: "banner",
  marketplaceId: "marketplaceId",
  is_featured: "is_featured",
};

export const productsListParams = {
  status: "Published",
  limit: 1000,
  skip: 0,
};


export const featuredOffer = [
  {
    id: 1,
    value: true,
    displayText: "Yes",
  },
  {
    id: 2,
    value: false,
    displayText: "No",
  },
];

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { useEditOffer } from "./hooks";
import Modals from "../../../../../components/modal";
import { useUser } from "../../../../../common-hooks/user";
import OfferForm from "../form/offer-form";

const EditOffer = ({ offer }) => {
  const {
    editOfferLoading,
    isOpenModal,
    selectedOffer,
    products,
    marketplaces,
    getOfferFieldData,
    handleEditOffer,
    toggleModal,
  } = useEditOffer(offer);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            getOfferFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <OfferForm
              handleOfferForm={handleEditOffer}
              btn="Save"
              fieldValues={selectedOffer}
              isLoading={editOfferLoading}
              products={products}
              marketplaces={marketplaces}
            />
          }
          title="Edit Offer"
        />
      ) : null}
    </div>
  );
};

export default EditOffer;

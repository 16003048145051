import React from "react";
import DataTable from "../../../../components/table";
import { useCategoryOffer } from "./hooks";
import { columns } from "./constant";
import EditCategory from "./edit-category";
import AddCategory from "./add-category";

const OfferCategory = () => {
  const { categories, loadingCategories, categoriesCount, filters, setFilter } =
    useCategoryOffer();

  const configureTableData = () => {
    try {
      let results = categories.map((category) => {
        let newCategories = Object.assign({}, category);
        newCategories["action"] = (
          <div className="categories__list-action">
            <EditCategory category={category} />
          </div>
        );
        return newCategories;
      });
      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Categories"
      addBtn={<AddCategory />}
      CreateTableData={configureTableData}
      withFilter={false}
      handleSetFilter={setFilter}
      count={categoriesCount}
      skip={filters?.skip}
      isLoading={loadingCategories}
    />
  );
};

export default OfferCategory;

import { combineReducers } from "redux";
import authReducer from "../containers/auth/reducer";
import userReducer from "../containers/users/reducer";
import categoryReducer from "../containers/categories/reducer";
import eventReducer from "../containers/events/reducer";
import snackbarReducer from "../components/snackbar/reducer";
import scheduleEventReducer from "../containers/schedule-events/reducer";
import interestReducer from "../containers/Interests/reducer";
import fileReducer from "../utils/upload-file/reducer";
import planReducer from "../containers/plan/reducer";
import faqReducer from "../containers/faq/reducer";
import userDetailsReducer from "../containers/users/user-details/reducer";
import blogsReducer from "../containers/CMS/blog/blogs/reducer";
import seniorTodayBlogReducer from "../containers/CMS/blog/senior-today-blogs/reducer";
import magazineReducer from "../containers/CMS/magazines/reducer";
import labelsReducer from "../containers/CMS/blog/labels/reducer";
import videoReducer from "../containers/CMS/blog/videos/reducer";
import sectionsReducer from "../containers/CMS/blog/sections/reducer";
import pagesReducer from "../containers/CMS/blog/pages/reducer";
import popularKeywordsReducer from "../containers/CMS/events/popular-keywords/reducer";
import policyReducer from "../containers/CMS/policy/reducer";
import commentsReducer from "../containers/CMS/blog/comments/reducer";
import noticeReducer from "../containers/Notices/reducer";
import financeReducer from "../containers/dashboard/reducer";
import bannerReducer from "../containers/CMS/banners/reducer";
import configReducer from "../containers/CMS/settings/reducer";
import offerReducer from "../containers/Offers/reducer";
import partnerReducer from "../containers/offer-partners/reducer";
import courseReducer from "../containers/courses/reducer";
import unlockedOfferReducer from "../containers/Unlocked Offers/reducer";
import testimonialReducer from "../containers/CMS/testimonials/reducer";
import postsReducer from "../containers/social/posts/reducer";
import reportOptionReducer from "../containers/social/reports-options/reducer";
import subCategoryReducer from "../containers/categories/subcategories/reducer";
import layoutReducer from "../containers/Layout/reducer";
import voucherReducer from "../containers/vouchers/reducer";
import storyReducer from "../containers/social/stories/reducer";
import commentAndRepliesReducer from "../containers/social/reported-comments-and-replies/reducer";
import reportsReducer from "../containers/reports-data/reducer";
import metaDataReducer from "../containers/CMS/meta-data/reducer";
import brandReducer from "../containers/Services/offers/brand/reducer";
import productReducer from "../containers/Services/offers/product/reducer";
import serviceOfferReducer from "../containers/Services/offers/offer/reducer";
import marketplaceReducer from "../containers/Services/offers/marketplace/reducer";

const createReducer = () => {
  const comnbinedAppReducer = combineReducers({
    authReducer,
    userReducer,
    categoryReducer,
    eventReducer,
    snackbarReducer,
    scheduleEventReducer,
    interestReducer,
    fileReducer,
    planReducer,
    faqReducer,
    userDetailsReducer,
    blogsReducer,
    seniorTodayBlogReducer,
    magazineReducer,
    labelsReducer,
    sectionsReducer,
    pagesReducer,
    popularKeywordsReducer,
    policyReducer,
    commentsReducer,
    noticeReducer,
    videoReducer,
    financeReducer,
    bannerReducer,
    configReducer,
    offerReducer,
    partnerReducer,
    courseReducer,
    unlockedOfferReducer,
    testimonialReducer,
    postsReducer,
    reportOptionReducer,
    subCategoryReducer,
    layoutReducer,
    voucherReducer,
    storyReducer,
    commentAndRepliesReducer,
    reportsReducer,
    metaDataReducer,
    brandReducer,
    productReducer,
    serviceOfferReducer,
    marketplaceReducer,
  });

  const rootReducer = (state, action) => {
    return comnbinedAppReducer(state, action);
  };
  return rootReducer;
};

export default createReducer;

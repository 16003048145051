import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCourse,
  getUserList,
  getCourseList,
  getEventList,
} from "../actions";
import { clearFiles } from "../../../utils/upload-file/actions";
import { getCourses, getCommonFilter } from "../selectors";
import { useHistory } from "react-router-dom";
import { showSnackbar } from "../../../components/snackbar/actions";
import { fileContext, snackbarTypes } from "../../../constants";
import * as moment from "moment";
import { clearLanguage } from "../../../utils/auth";

export const useAddCourse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addCourseLoading, setAddCourseLoading] = useState(false);
  const courses = useSelector(getCourses);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const filters = useSelector(getCommonFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  useEffect(() => {
    clearLanguage();
    const getRequiredData = async () => {
      setAddCourseLoading(true);
      dispatch(clearFiles());
      const data = await dispatch(getUserList(filters));
      if (data) {
        setUsers(data);
      }
      await dispatch(getCourseList(filters));
      const { events } = await dispatch(getEventList(filters));
      if (events) {
        setEvents(events);
      }
      setAddCourseLoading(false);
    };
    getRequiredData();
  }, [dispatch, filters]);

  const handleAddCourse = async (values) => {
    let { event_schedules, requirements } = values;

    let error = false;

    Object.keys(requirements).forEach((key) => {
      if (requirements[key].length) {
        requirements[key].forEach((val) => {
          const { title, description } = val;
          if (!title) {
            dispatch(
              showSnackbar({
                message: "Enter valid title or decription for requirements",
                type: snackbarTypes.ERROR,
              })
            );
            error = true;
            return;
          }

          description.forEach((desc) => {
            if (!desc) {
              dispatch(
                showSnackbar({
                  message: "Enter valid  decription for requirements",
                  type: snackbarTypes.ERROR,
                })
              );
              error = true;
              return;
            }
          });
        });
      }
    });

    for (let i = 0; i < event_schedules.length; i++) {
      let current = event_schedules[i];
      for (let j = 0; j < event_schedules.length; j++) {
        if (i !== j) {
          if (
            moment(event_schedules[j].starts_at).isSame(
              moment(current.starts_at)
            ) ||
            moment(event_schedules[j].starts_at).isBetween(
              moment(current.starts_at),
              moment(current.ends_at)
            )
          ) {
            dispatch(
              showSnackbar({
                message: "Multiple events cannot be scheduled on same time",
                type: snackbarTypes.ERROR,
              })
            );
            return;
          }
        }
      }
    }

    for (let k = 0; k < requirements.length; k++) {
      let description = requirements[k]["description"];
      for (let l = 0; l < description.length; l++) {
        if (!description[l]) {
          dispatch(
            showSnackbar({
              message: "Enter valid description for requirement",
              type: snackbarTypes.ERROR,
            })
          );
          return;
        }
      }
    }

    if (!values.event_schedules.find((ev) => ev.type === "Regular")) {
      dispatch(
        showSnackbar({
          message: "Atleast One Regular Event Schedule is Required",
          type: snackbarTypes.ERROR,
        })
      );
      return;
    }

    if (
      values.allow_booking_post_commencement &&
      values.event_schedules.length > 1
    ) {
      let dates = [];
      values.event_schedules.forEach((e) => {
        dates.push(new Date(e.starts_at));
      });
      var min = dates.reduce(function (a, b) {
        return a < b ? a : b;
      });
      var max = dates.reduce(function (a, b) {
        return a > b ? a : b;
      });
      if (
        values.allow_booking_post_commencement < min ||
        values.allow_booking_post_commencement > max
      ) {
        dispatch(
          showSnackbar({
            message: "Last Date and Time to Book Seats Should be Valid !",
            type: snackbarTypes.ERROR,
          })
        );
        return;
      }

      if (
        values.allow_seats_increment < min ||
        values.allow_seats_increment > values.allow_booking_post_commencement
      ) {
        dispatch(
          showSnackbar({
            message:
              "Last Date and Time to Increase Displayed Seat Count Should be Valid !",
            type: snackbarTypes.ERROR,
          })
        );
        return;
      }
    }

    if (!error) {
      try {
        let ev_sh = event_schedules.map((ev) => {
          return { ...ev };
        });

        ev_sh.forEach((ele, i) => {
          ev_sh[i].pre_event_file = {};
          files.forEach((file) => {
            if (file.file_context === `${fileContext.EVENT_ATTACHMENT}_${i}`) {
              const { uuid_file_name, original_file_name } = file;
              ev_sh[i].pre_event_file = { uuid_file_name, original_file_name };
            }
          });
        });
        ev_sh.forEach((ev, i) => {
          ev_sh[i].current_attendees_limit = parseInt(
            ev.current_attendees_limit
          );
          ev_sh[i].attendees_limit = parseInt(ev.attendees_limit);
          ev_sh[i].exceeds_percentage = parseInt(ev.exceeds_percentage);
          ev_sh[i].increment_percentage = parseInt(ev.increment_percentage);
          ev_sh[i].waiting_room_start_time = parseInt(
            values.waiting_room_start_time ? values.waiting_room_start_time : 0
          );
          ev_sh[i].allow_booking_post_commencement =
            ev.type === "Regular"
              ? ev.allow_booking_post_commencement
              : moment(ev.starts_at)
                  .add(parseInt(ev.allow_booking_post_commencement), "minutes")
                  .toString();
          ev_sh[i].allow_seats_increment =
            ev.type === "Regular"
              ? ev.allow_seats_increment
              : moment(ev.starts_at)
                  .add(parseInt(ev.allow_seats_increment), "minutes")
                  .toString();
        });
        ev_sh.forEach((ev) => {
          delete ev.btn;
          delete ev.note;
        });

        setAddCourseLoading(true);
        const payload = {
          event_id: values.event_id?.id,
          event_schedules: ev_sh,
          // commence_at: values.commence_at,
          mode: values.mode,
          language: values.language,
          per_session_cost: parseInt(values.per_session_cost),
          attendees_limit: parseInt(values.attendees_limit),
          current_attendees_limit: parseInt(values.current_attendees_limit),
          exceeds_percentage: parseInt(values.exceeds_percentage),
          increment_percentage: parseInt(values.increment_percentage),
          allow_booking_post_commencement:
            values.allow_booking_post_commencement,
          allow_seats_increment: values.allow_seats_increment,
          starred: values.starred?.value,
          status: values.status,
          requirements: values.requirements,
          host_ids: [values.hostIds.id],
          cohost_ids: values.cohostIds.map((cohost) => cohost.id),
        };

        const is_success = await dispatch(addCourse(payload));
        setAddCourseLoading(false);
        if (is_success) {
          dispatch(clearFiles());
          history.push("/courses");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return {
    addCourseLoading,
    handleAddCourse,
    users,
    courses,
    events,
  };
};

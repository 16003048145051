import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/services/offers/products";

export const SET_PRODUCTS_LIST = `${namespace}/SET_PRODUCTS_LIST`;
export const SET_CATEGORY_LIST = `${namespace}/SET_CATEGORY_LIST`;
export const SET_PRODUCTS_FILTER = `${namespace}/SET_PRODUCTS_FILTER`;

const setProductsList = (payload) => ({
  type: SET_PRODUCTS_LIST,
  payload,
});

export const setProductsFilter = (payload) => ({
  type: SET_PRODUCTS_FILTER,
  payload,
});

export const setCategoryList = (payload) => ({
  type: SET_CATEGORY_LIST,
  payload,
});

export const getProductsList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.products, {
      params: { params },
    });
    const { data, data: { is_success } = {} } = response;
    if (is_success) {
      dispatch(setProductsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getProduct = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSingleProduct(id));
    const { data: { is_success, product, banner, icon } = {} } = response;
    if (is_success) {
      return { product, files: [banner, icon] };
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addProduct = (payload) => async (dispatch) => {
  try {
    const response = await api.post(
      apiEndPoints.addProduct(payload?.product?.brand_id),
      payload
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Product not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const editProduct = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(apiEndPoints.updateProduct(id), payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Product not edited, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteInterest = (id) => async (dispatch) => {
  try {
    const response = await api.delete(apiEndPoints.deleteInterests(id));
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(getProductsList());
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getCategoryList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getCategories, {
      params: { params },
    });
    const { data: { is_success, categories } = {} } = response;
    if (is_success) {
      dispatch(setCategoryList(categories));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

import * as Yup from "yup";
import moment from "moment";
import { eventDatesLimit, eventTimings } from "../constant";

export const scheduleEventFormSchema = Yup.object().shape({
  no_of_credits: Yup.string().when("type",{
    is:"Paid",
    then:Yup.string().required("Credits are required")
  }),
  event_id: Yup.string().required("Event is required").nullable(),
  startsAt: Yup.date()
    .required("Start date and time is required")
    .when("is_edit", {
      is: true,
      then: Yup.date().test(
        "is-greater-than",
        "Start date and time must be greater than current date and time",
        function () {
          const { startsAt, isEdit } = this.parent;

          if (!isEdit) {
            return moment().isAfter(moment(startsAt)) ? false : true;
          } else {
            return true;
          }
        }
      ),
    })
    .when("is_edit", {
      is: false,
      then: Yup.date().test(
        "should-be-greater-than-current-time",
        "Start time must be greater than current date and time",
        function () {
          const { startsAt, dates } = this.parent;
          const isExist = dates.find((obj) =>
            moment(new Date(obj)).isSame(new Date(), "day")
          );

          if (isExist) {
            const isAfter = moment(new Date(startsAt)).isAfter(
              new Date(),
              "minute"
            );
            return isAfter;
          }

          return true;
        }
      ),
    }),

  endsAt: Yup.date()
    .required("End date and time is required")
    .when("is_edit", {
      is: true,
      then: Yup.date()
        .min(
          new Date(),
          "End date and time must be greater than current date and time"
        )
        .min(
          Yup.ref("startsAt"),
          "End date and time can't be equal or before start date time"
        )
        .required("End date and time is required")
        .test(
          "is-less-than",
          `Event must be of minimum ${eventTimings.minTime} minutes`,
          function () {
            const { startsAt, endsAt } = this.parent;

            const date1 = new Date(
              moment(startsAt).format("MM-DD-YYYY HH:mm:ss")
            );
            const date2 = new Date(
              moment(endsAt).format("MM-DD-YYYY HH:mm:ss")
            );
            const diffInMs = Math.abs(date2 - date1);
            const diff = diffInMs / (1000 * 60);

            return diff < eventTimings.minTime ? false : true;
          }
        )
        .test(
          "is-greater-than",
          `Event must be of maximum ${eventTimings.maxTime} hours`,
          function () {
            const { startsAt, endsAt } = this.parent;

            const date1 = new Date(
              moment(startsAt).format("MM-DD-YYYY HH:mm:ss")
            );
            const date2 = new Date(
              moment(endsAt).format("MM-DD-YYYY HH:mm:ss")
            );
            const diffInMs = Math.abs(date2 - date1);
            const diff = diffInMs / (1000 * 60);

            return diff > eventTimings.maxTime * 60 ? false : true;
          }
        ),
    })
    .when("is_edit", {
      is: false,
      then: Yup.date()
        .test(
          "is-less-than",
          `Event must be of minimum ${eventTimings.minTime} minutes`,
          function () {
            const { startsAt, endsAt } = this.parent;

            const startMins = startsAt.getHours() * 60 + startsAt.getMinutes();
            const endMins = endsAt.getHours() * 60 + endsAt.getMinutes();

            const diff =
              endMins >= startMins
                ? endMins - startMins
                : 1440 - startMins + endMins; //1440 === 24 * 60 (minutes in a day)

            return diff < eventTimings.minTime ? false : true;
          }
        )
        .test(
          "is-greater-than",
          `Event must be of maximum ${eventTimings.maxTime} hours`,
          function () {
            const { startsAt, endsAt } = this.parent;

            const startMins = startsAt.getHours() * 60 + startsAt.getMinutes();
            const endMins = endsAt.getHours() * 60 + endsAt.getMinutes();

            const diff =
              endMins >= startMins
                ? endMins - startMins
                : 1440 - startMins + endMins; //1440 === 24 * 60 (minutes in a day)

            return diff > eventTimings.maxTime * 60 ? false : true;
          }
        ),
    }),

  dates: Yup.array().when("is_edit", {
    is: false,
    then: Yup.array()
      .required("Dates are required")
      .max(eventDatesLimit, `You can select upto ${eventDatesLimit} dates`),
  }),
  repeat: Yup.string().required("Repeat is required"),
  status: Yup.string().required("Status is required"),
  language: Yup.string().required("Language is required"),
  mode: Yup.string().required("Mode is required"),
  attendeesLimit: Yup.number()
    .min(5, "Minimum Optimum Batch Size is 5.")
    .required("Optimum Batch Size is required"),

  current_attendees_limit: Yup.number()
    .required("Displayed Seats Count limit is required")
    .min(1, "Minimum Optimum Batch Size is 1")
    .test(
      "is-less-than",
      "Displayed Seats Count must be less than Optimum Batch Size",
      function () {
        const { current_attendees_limit, attendeesLimit } = this.parent;
        return current_attendees_limit <= attendeesLimit;
      }
    )
    .test(
      "is-less-than",
      "Displayed Seats Count must be greater than Booked Seats",
      function () {
        const { current_attendees_limit, booked } = this.parent;
        if (booked) {
          return booked <= current_attendees_limit;
        }
        return true;
      }
    ),
  exceeds_percentage: Yup.number()
    .required("Threshold % for changing Displayed Seats Count is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-less-than",
      "Value should be less than 100",
      function (exceeds_percentage) {
        return exceeds_percentage <= 100;
      }
    ),
  increment_percentage: Yup.number()
    .required("% for updating new Displayed Seats Count is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-less-than",
      "Value should be less than 100",
      function (increment_percentage) {
        return increment_percentage <= 100;
      }
    ),
  allow_booking_post_commencement: Yup.number()
    .required("Allow Booking Till is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-greater-than",
      "Allow Booking Till value must be less than event duration",
      function () {
        const { allow_booking_post_commencement, startsAt, endsAt } =
          this.parent;
        const date1 = new Date(moment(startsAt).format("MM-DD-YYYY HH:mm:ss"));
        const date2 = new Date(moment(endsAt).format("MM-DD-YYYY HH:mm:ss"));
        const diffInMs = Math.abs(date2 - date1);
        const diff = diffInMs / (1000 * 60);

        return diff > allow_booking_post_commencement;
      }
    ),
  allow_seats_increment: Yup.number()
    .required("Seat Increment Till is required")
    .min(1, "This field should have non zero value.")
    .test(
      "is-greater-than",
      "Allow Booking Till value must be greater than Seat Increment Till value",
      function () {
        const { allow_booking_post_commencement, allow_seats_increment } =
          this.parent;

        return allow_booking_post_commencement > allow_seats_increment;
      }
    ),
  hostIds: Yup.object().required("Host is required").nullable(),
  cohostIds: Yup.array().required("Co-Host is required"),
  requirements: Yup.object(),
  is_attachment: Yup.boolean(),
  cta_title: Yup.string().when("is_attachment", {
    is: true,
    then: Yup.string().required("CTA Title is required"),
  }),
  attachment_file: Yup.array().when("is_attachment", {
    is: true,
    then: Yup.array().required("File is required"),
  }),
  broadcast_mins: Yup.number()
    .when("streaming_type", {
      is: "Yes",
      then: Yup.number().required("View Live Session is required"),
    })
    .min(1, "This field should have non zero value."),

  after_popup_broadcast_mins: Yup.number()
    .when("streaming_type", {
      is: "Yes",
      then: Yup.number().required("View Live Session is required"),
    })
    .min(1, "This field should have non zero value.")
    .test(
      "is-less-than",
      "Rewatch Live Sessiom must be less than View Live Session",
      function () {
        const {
          broadcast_mins,
          after_popup_broadcast_mins,
          type,
          streaming_type,
        } = this.parent;
        if (type === "Free" && streaming_type === "Yes")
          return after_popup_broadcast_mins < broadcast_mins;
        return true;
      }
    ),
  max_join_broadcast_attempts: Yup.number()
    .when("streaming_type", {
      is: "Yes",
      then: Yup.number().required(
        "Number of Times Second Pop-Up Shows value is required"
      ),
    })
    .min(1, "This field should have non zero value."),
  waiting_room_start_time: Yup.number(),
});

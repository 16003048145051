import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";

import { TextField, MenuItem } from "@material-ui/core";
import { offerCategoryFormSchema } from "./validation-schema";
import { categoryStatus, fieldsName } from "../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { DataLoader } from "../../../../../components/loader";
import { initialLanguageFieldValue } from "../../../../../constants";

const formField = {
  title: initialLanguageFieldValue,
  status: "",
};

const OfferCategoryForm = ({
  fieldValues,
  handleOfferCategoryForm,
  btn,
  isLoading,
}) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();

  const { title, status } = fieldsName;

  //To use it when form reinitialized
  const [formValues, setFormvalues] = useState(fieldValues || formField);

  const handleFormUpdate = (event) => {
    const { name, value } = event.target;
    setFormvalues((prevFieldValue) => ({
      ...prevFieldValue,
      [name]: value,
    }));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={offerCategoryFormSchema}
      initialValues={{
        title: formValues?.title || initialLanguageFieldValue,
        status: formValues?.status || formValues?.interest?.status || "",
        language: formValues?.language || "en",
      }}
      onSubmit={handleOfferCategoryForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  type="text"
                  value={rest.values.title[rest.values.language]}
                  onChange={(event) => {
                    handleFormValueChange(rest, title);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {categoryStatus.map((interest) => (
                  <MenuItem
                    key={interest.id}
                    className="material-form__option"
                    value={interest.value}
                  >
                    {interest.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>
            <button
              type="submit"
              className="btn btn-success btn-right btn-sm interest__marginTop30"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default OfferCategoryForm;

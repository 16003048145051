import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../../utils/auth";
import { getProductCount, getProductFilter, getProducts } from "./selectors";
import { getProductsList, setProductsFilter } from "./actions";

export const useProducts = () => {
  clearLanguage();
  const dispatch = useDispatch();
  const products = useSelector(getProducts);
  const productsCount = useSelector(getProductCount);
  const filters = useSelector(getProductFilter);
  const [loadingProducts, setLoadingProducts] = useState(false);

  useEffect(() => {
    const fetchInterests = async () => {
      setLoadingProducts(true);
      await dispatch(getProductsList(filters));
      setLoadingProducts(false);
    };
    fetchInterests();
  }, [dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setProductsFilter({ key, value }));
  };

  return {
    products,
    loadingProducts,
    filters,
    productsCount,
    setFilter,
  };
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMagazineData, getMagazineList } from "../actions";
import { getMagazineFilter } from "../selectors";

export const useDeleteMagazine = (id) => {
  const dispatch = useDispatch();
  const filter = useSelector(getMagazineFilter);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteMagazineLoading, setDeleteMagazineLoading] = useState(false);

  const handleDeleteMagazine = async () => {
    setDeleteMagazineLoading(true);
    const is_success = await dispatch(deleteMagazineData(id));
    setDeleteMagazineLoading(false);
    if (is_success) {
      dispatch(getMagazineList(filter));
      toggleModal();
    }
  };
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return {
    deleteMagazineLoading,
    handleDeleteMagazine,
    isOpenModal,
    toggleModal,
  };
};

export const columns = [
  {
    Header: "ID",
    accessor: "id",
    width: 50,
  },

  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "action",
    width: 80,
  },
];

export const brandStatus = [
  {
    id: 1,
    value: "Published",
    displayText: "Published",
  },
  {
    id: 2,
    value: "Unpublished",
    displayText: "Unpublished",
  },
];

export const planList = [
  {
    id: 1,
    value: "Free",
    displayText: "Free",
  },
  {
    id: 2,
    value: "Silver",
    displayText: "Silver",
  },
  {
    id: 3,
    value: "Bronze",
    displayText: "Bronze",
  },
  {
    id: 4,
    value: "Gold",
    displayText: "Gold",
  },
  {
    id: 5,
    value: "Platinum",
    displayText: "Platinum",
  },
  {
    id: 6,
    value: "TopUp",
    displayText: "TopUp",
  },
];

export const fieldsName = {
  name: "name",
  status: "status",
  logo: "logo",
  banner: "banner",
};

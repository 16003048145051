import React from "react";
import DataTable from "../../../../components/table";
import { columns} from "./constant";
import { useBrands } from "./hooks";

import EditBrand from "./edit-brand";
import AddBrand from "./add-brand";

function Brands() {
  const {
    brands,
    loadingBrands,
    filters,
    brandsCount,
    setFilter,
  } = useBrands();

  const configureTableData = () => {
    try {
      let results = brands.map((brand) => {
        let newBrand = Object.assign({}, brand);

        newBrand["action"] = <EditBrand brand={brand} />;

        return newBrand;
      });

      const reactTableData = {
        tableHeaderData: columns,
        tableRowsData: results,
      };
      return reactTableData;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DataTable
      title="Brands"
      addBtn={<AddBrand />}
      CreateTableData={configureTableData}
      handleSetFilter={setFilter}
      count={brandsCount}
      skip={filters?.skip}
      isLoading={loadingBrands}
    />
  );
}

export default Brands;

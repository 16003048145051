import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { useEditInterest } from "./hooks";
import Modals from "../../../../../components/modal";
import { useUser } from "../../../../../common-hooks/user";
import ProductForm from "../form/product-form";

const EditProduct = ({ product }) => {
  const {
    editProductLoading,
    isOpenModal,
    selectedProduct,
    categories,
    brands,
    getProductFieldData,
    handleEditProduct,
    toggleModal,
  } = useEditInterest(product);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Edit" placement="bottom">
        <i
          className={`fa fa-edit icon-btn-action`}
          onClick={() => {
            getProductFieldData();
          }}
        ></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <ProductForm
              handleInterestForm={handleEditProduct}
              btn="Save"
              fieldValues={selectedProduct}
              isLoading={editProductLoading}
              categories={categories}
              brands={brands}
            />
          }
          title="Edit Product"
        />
      ) : null}
    </div>
  );
};

export default EditProduct;

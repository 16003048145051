import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { TextField, MenuItem, Chip, Tooltip } from "@material-ui/core";

import { fieldsName, brandStatus } from "../constant";
import { DataLoader } from "../../../../../components/loader";
import { brandFormSchema } from "./validation-schema";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { fileContext, imageDimensions } from "../../../../../constants";
import ProgressChip from "../../../../../components/progress-chip";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import Croppier from "../../../../../components/image-croppier";

const formField = {
  brand_name: "",
  status: "",
};

const BrandForm = ({ fieldValues, handleBrandForm, btn, isLoading }) => {
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();
  const [currentBrandLogo, setCurrentBrandLogo] = useState([]);

  const { brand_logo } = imageDimensions;

  const {
    uploadError,
    files,
    isFileUploadData,
    uploadProgress,
    handleCropping,
    handleDeleteAFile,
    handleBrandLogoFile,
  } = useUploadFiles();
  const [formValues, setFormValues] = useState(fieldValues || formField);

  const { brand_name, status, logo } = fieldsName;

  const handleFormUpdate = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Formik
      validateOnMount
      validationSchema={brandFormSchema}
      enableReinitialize
      initialValues={{
        brand_name: formValues?.brand_name || "",
        status: formValues?.status || "",
        logo: files.filter(
          (file) => file.file_context === fileContext.BRAND_LOGO
        ),
      }}
      onSubmit={handleBrandForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={brand_name}
                placeholder="Brand Name"
                label="Brand Name"
                value={rest.values.brand_name}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleFormUpdate(name, value);
                  handleFormValueChange(rest, name);
                }}
                onFocus={() => handleFocusField(brand_name)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.brand_name}
              />

              {focusField === brand_name ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={brand_name}
                />
              )}
            </div>

            <div className="materila-form_inline-child">
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleFormUpdate(name, value);
                  handleFormValueChange(rest, status);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {brandStatus.map((status) => (
                  <MenuItem
                    key={status.id}
                    className="material-form__option"
                    value={status.value}
                  >
                    {status.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>

            <div className="form__form-group-field form__margin-bottom">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <p style={{ fontSize: 11, marginBottom: 5 }}>
                    Brand Logo Image (Preffered Size is 345px * 160px)
                  </p>
                  <label htmlFor="logo">Logo</label>

                  {files
                    .filter(
                      (file) => file.file_context === fileContext.BRAND_LOGO
                    )
                    .map((x) => {
                      return (
                        <Chip
                          className="selected-file"
                          key={x.uuid_file_name}
                          label={x.original_file_name}
                          onDelete={() => handleDeleteAFile(x)}
                          variant="outlined"
                        />
                      );
                    })}

                  {isFileUploadData
                    .filter(
                      (file) => file.fileContext === fileContext.BRAND_LOGO
                    )
                    .map((x, i) => {
                      return (
                        <div key={i}>
                          {x.isUpload && (
                            <ProgressChip
                              fileName={x.fileName}
                              uploadProgress={uploadProgress}
                            />
                          )}
                        </div>
                      );
                    })}

                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={logo}
                    id="logo"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleCropping(
                        event.currentTarget.files,
                        setFieldValue,
                        logo,
                        fileContext.BRAND_LOGO,
                        handleBrandLogoFile,
                        brand_logo,
                        setCurrentBrandLogo
                      );
                    }}
                  />
                </div>
                {rest.errors.logo ? (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form-error-image">{msg}</div>
                    )}
                    name={logo}
                  />
                ) : (
                  <div className="material-form-error-image">{uploadError}</div>
                )}
              </div>
            </div>
            {currentBrandLogo?.length > 0 &&
              currentBrandLogo?.map((logo, i) => (
                <div key={i}>
                  <Croppier
                    image={logo}
                    setFieldValue={setFieldValue}
                    name={logo}
                    context={fileContext.BRAND_LOGO}
                    dimensions={brand_logo}
                    handleUploadFile={handleBrandLogoFile}
                    setCurrentBanner={setCurrentBrandLogo}
                    index={i}
                  />
                </div>
              ))}

            <button type="submit" className="btn btn-success btn-right btn-sm">
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BrandForm;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLanguage } from "../../../../utils/auth";
import { getOffers, getOffersCount, getOffersFilter } from "./selectors";
import { getOffersList, setOffersFilter } from "./actions";

export const useServiceOffers = () => {
  clearLanguage();
  const dispatch = useDispatch();
  const offers = useSelector(getOffers);
  const offersCount = useSelector(getOffersCount);
  const filters = useSelector(getOffersFilter);
  const [loadingOffers, setLoadingOffers] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoadingOffers(true);
      await dispatch(getOffersList(filters));
      setLoadingOffers(false);
    };
    fetchProducts();
  }, [dispatch, filters]);

  const setFilter = (key, value) => {
    dispatch(setOffersFilter({ key, value }));
  };

  return {
    offers,
    loadingOffers,
    filters,
    offersCount,
    setFilter,
  };
};

import React from "react";
import { Formik, ErrorMessage } from "formik";

import { TextField, Chip, MenuItem, Tooltip, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataLoader } from "../../../../../components/loader";
import { useUploadFiles } from "../../../../../utils/upload-file/hooks";
import { productFormSchema } from "./validation-schema";
import { productStatus, fieldsName } from "../constant";
import {
  handleFormValueChange,
  useHandleFieldFocus,
} from "../../../../../utils/form";
import { fileContext, imageDimensions } from "../../../../../constants";
import ProgressChip from "../../../../../components/progress-chip";
import Croppier from "../../../../../components/image-croppier";
import { useProductForm } from "./hooks";
import TextfieldChips from "../../../../../components/text-field-chips";

const ProductForm = (props) => {
  const { product_banner, product_icon } = imageDimensions;
  const {
    handleProductIconFile,
    handleProductBannerFile,
    handleCropping,
    uploadError,
    fileIconError,
    handleDeleteAFile,
    files,
    isFileUploadData,
    uploadProgress,
  } = useUploadFiles();

  const {
    currentProductBanner,
    currentProductIcon,
    handleInterestForm,
    btn,
    isLoading,
    categories,
    brands,
    formValues,
    handleCategoryUpdate,
    handleFormUpdate,
    setCurrentProductIcon,
    setcurrentProductBanner,
    handleFormArrayUpdate,
  } = useProductForm(props);
  const { focusField, handleFocusField, handleBlurField } =
    useHandleFieldFocus();
  const { title, status, fileIcon, categoryId, fileBanner, about, brandId } =
    fieldsName;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={productFormSchema}
      initialValues={{
        title: formValues?.title || formValues?.name || "",
        status: formValues?.status || "",
        about: formValues?.about || [],
        fileIcon: files.filter(
          (file) => file.file_context === fileContext.PRODUCT_ICON
        ),
        fileBanner: files.filter(
          (file) => file.file_context === fileContext.PRODUCT_BANNER
        ),
        brandId:
          (formValues?.brand_id &&
            brands.find((brand) => brand.id === formValues?.brand_id)) ||
          (formValues?.brandId &&
            brands.find((brand) => brand.id === formValues?.brandId.id)) ||
          "",
        categoryId:
          (formValues?.category_id &&
            categories.find(
              (category) => category.id === formValues?.category_id
            )) ||
          (formValues?.categoryId &&
            categories.find(
              (category) => category.id === formValues?.categoryId.id
            )) ||
          "",
      }}
      onSubmit={handleInterestForm}
    >
      {({ handleSubmit, setFieldValue, ...rest }) => (
        <>
          {isLoading ? <DataLoader /> : null}
          <form
            className="material-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="material-form_inline-fields d-flex align-items-center">
              <div className="materila-form_inline-child">
                <TextField
                  className="material-form__field"
                  name={title}
                  placeholder="Title"
                  label="Title"
                  value={rest.values.title}
                  onChange={(event) => {
                    handleFormUpdate(event);
                    handleFormValueChange(rest, title);
                  }}
                  onFocus={() => handleFocusField(title)}
                  onBlur={() => handleBlurField()}
                />
                {focusField === title ? null : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={title}
                  />
                )}
              </div>
            </div>
            <div>
              <TextfieldChips
                initialChips={rest.values.about}
                name="About"
                onDataChange={(data) => {
                  setFieldValue(about, data);
                  handleFormArrayUpdate(about, data);
                }}
              />
              {focusField === about ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={about}
                />
              )}
            </div>
            <div>
              <TextField
                className="material-form__field"
                name={status}
                placeholder="Status"
                label="Status"
                select
                value={rest.values.status}
                onChange={(event) => {
                  handleFormValueChange(rest, status);
                  handleFormUpdate(event);
                }}
                onFocus={() => handleFocusField(status)}
                onBlur={() => handleBlurField()}
                defaultValue={rest.values.status}
              >
                {productStatus.map((interest) => (
                  <MenuItem
                    key={interest.id}
                    className="material-form__option"
                    value={interest.value}
                  >
                    {interest.displayText}
                  </MenuItem>
                ))}
              </TextField>
              {focusField === status ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={status}
                />
              )}
            </div>
            <div>
              <Autocomplete
                options={categories}
                getOptionLabel={(category) =>
                  category.title ? category.title : ""
                }
                value={rest.values.categoryId}
                name={categoryId}
                defaultValue={rest.values.categoryId}
                onChange={(event, value) => {
                  setFieldValue(categoryId, value);
                  handleCategoryUpdate(categoryId, value);
                }}
                onFocus={() => handleFocusField(categoryId)}
                onBlur={() => handleBlurField()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={categoryId}
                    placeholder="Select Categories"
                    label="Select Categories"
                    variant="standard"
                  />
                )}
              />
              {focusField === categoryId ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={categoryId}
                />
              )}
            </div>
            <div>
              <Autocomplete
                options={brands}
                getOptionLabel={(brand) => (brand.name ? brand.name : "")}
                value={rest.values.brandId}
                name={brandId}
                onChange={(event, value) => {
                  setFieldValue(brandId, value);
                  handleCategoryUpdate(brandId, value);
                }}
                onFocus={() => handleFocusField(brandId)}
                onBlur={() => handleBlurField()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="material-form__field"
                    name={brandId}
                    placeholder="Select Brand"
                    label="Select Brand"
                    variant="standard"
                  />
                )}
              />
              {focusField === brandId ? null : (
                <ErrorMessage
                  render={(msg) => (
                    <div className="material-form__error">{msg}</div>
                  )}
                  name={brandId}
                />
              )}
            </div>
            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <p style={{ fontSize: 11, marginBottom: 5 }}>
                    Cover Square Image (Preffered Size is 100px * 100px)
                  </p>
                  <label htmlFor="fileIcon">Choose Icon</label>

                  <span>
                    {files
                      .filter(
                        (file) => file.file_context === fileContext.PRODUCT_ICON
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) => file.fileContext === fileContext.PRODUCT_ICON
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={fileIcon}
                    id="fileIcon"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleCropping(
                        event.currentTarget.files,
                        setFieldValue,
                        fileIcon,
                        fileContext.PRODUCT_ICON,
                        handleProductIconFile,
                        product_icon,
                        setCurrentProductIcon
                      );
                      // handleProductIconFile(event.currentTarget.files[0]);
                      // setFieldValue({ fileIcon }, files);
                    }}
                  />
                </div>
                {fileIconError || uploadError ? (
                  <div className="material-form__error">
                    {fileIconError || uploadError}
                  </div>
                ) : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={fileIcon}
                  />
                )}
              </div>
            </div>
            {currentProductIcon?.length > 0 &&
              currentProductIcon?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={fileIcon}
                    context={fileContext.PRODUCT_ICON}
                    dimensions={{
                      width: 100,
                      height: 100,
                    }}
                    handleUploadFile={handleProductIconFile}
                    setCurrentBanner={setCurrentProductIcon}
                    index={i}
                  />
                </div>
              ))}

            <div className="form__form-group-field">
              <div className="form__form-group-input-wrap">
                <div className="form__form-group-file">
                  <p style={{ fontSize: 11, marginBottom: 5 }}>
                    Cover Image (Preffered Size is 375px * 200px)
                  </p>
                  <label htmlFor="fileBanner">Choose Banner</label>

                  <span>
                    {files
                      .filter(
                        (file) =>
                          file.file_context === fileContext.PRODUCT_BANNER
                      )
                      .map((x) => {
                        return (
                          <Chip
                            className="selected-file"
                            key={x.uuid_file_name}
                            label={x.original_file_name}
                            onDelete={() => handleDeleteAFile(x)}
                            variant="outlined"
                          />
                        );
                      })}

                    {isFileUploadData
                      .filter(
                        (file) =>
                          file.fileContext === fileContext.PRODUCT_BANNER
                      )
                      .map((x, i) => {
                        return (
                          <>
                            {x.isUpload && (
                              <ProgressChip
                                fileName={x.fileName}
                                uploadProgress={uploadProgress}
                              />
                            )}
                          </>
                        );
                      })}
                  </span>
                  <input
                    accept="image/*"
                    onBlur={rest.handleBlur}
                    type="file"
                    name={fileBanner}
                    id="fileBanner"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => {
                      handleCropping(
                        event.currentTarget.files,
                        setFieldValue,
                        fileBanner,
                        fileContext.PRODUCT_BANNER,
                        handleProductBannerFile,
                        product_banner,
                        setcurrentProductBanner
                      );
                      // handleProductBannerFile(event.currentTarget.files[0]);
                      // setFieldValue({ fileBanner }, files);
                    }}
                  />
                </div>
                {uploadError ? (
                  <div className="material-form__error">{uploadError}</div>
                ) : (
                  <ErrorMessage
                    render={(msg) => (
                      <div className="material-form__error">{msg}</div>
                    )}
                    name={fileBanner}
                  />
                )}
              </div>
            </div>
            {currentProductBanner?.length > 0 &&
              currentProductBanner?.map((banner, i) => (
                <div key={i}>
                  <Croppier
                    image={banner}
                    setFieldValue={setFieldValue}
                    name={fileBanner}
                    context={fileContext.PRODUCT_BANNER}
                    dimensions={{
                      width: 375,
                      height: 200,
                    }}
                    handleUploadFile={handleProductBannerFile}
                    setCurrentBanner={setcurrentProductBanner}
                    index={i}
                  />
                </div>
              ))}
            <button
              type="submit"
              className="btn btn-success btn-right btn-sm interest__marginTop30"
            >
              {btn}
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default ProductForm;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrandCount, getBrandFilter, getBrands } from "./selectors";
import { getBrandsList, resetBrandsFilter, setBrandsFilter } from "./actions";


export const useBrands = () => {
  const dispatch = useDispatch();
  const brands = useSelector(getBrands);
  const filters = useSelector(getBrandFilter);
  const brandsCount = useSelector(getBrandCount);

  const [loadingBrands, setLoadingBrands] = useState(false);

  useEffect(() => {
    const fetchBrands = async () => {
      setLoadingBrands(true);
      await dispatch(getBrandsList(filters));
      setLoadingBrands(false);
    };
    fetchBrands();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setBrandsFilter({ key, value }));
  };

  const resetFilter = () => {
    dispatch(resetBrandsFilter());
  };

  return {
    brands,
    loadingBrands,
    filters,
    brandsCount,
    setFilter,
    resetFilter,
  };
};

import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Modals from "../../../../../components/modal";
import { useEditBrand } from "./hooks";
import { useUser } from "../../../../../common-hooks/user";
import BrandForm from "../form/brand-form";

const EditBrand = ({ brand }) => {
  const {
    isOpenModal,
    selectedBrand,
    editBrandLoading,
    getBrand,
    handleEditBrand,
    toggleModal,
  } = useEditBrand(brand);

  const { isAdmin, isModerator } = useUser();

  if (!isAdmin && !isModerator) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" placement="bottom">
        <i className={`fa fa-edit icon-btn-action`} onClick={getBrand}></i>
      </Tooltip>
      {isOpenModal ? (
        <Modals
          isOpenModal
          setIsOpenModal={toggleModal}
          message={
            <BrandForm
              fieldValues={selectedBrand}
              handleBrandForm={handleEditBrand}
              btn="Save"
              isLoading={editBrandLoading}
            />
          }
          title="Edit Brand"
        />
      ) : null}
    </>
  );
};

export default EditBrand;

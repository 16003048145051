import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import MagazineForm from "../form/magazine-form";
import { useEditMagazine } from "./hooks";
import { useRouteMatch } from "react-router-dom";
import { Loader } from "../../../../components/loader";
const EditMagazine = () => {
  const match = useRouteMatch("/edit-magazine/:magazine_id");
  const {
    handleEditMagazine,
    editMagazineLoading,
    magazines,
    selectedMagazine,
    setSearchText,
  } = useEditMagazine({ match });
  return (
    <div>
      {editMagazineLoading ? (
        <Loader />
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title card__title__flex">
                  <h3 className="bold-text">EDIT MAGAZINE</h3>
                </div>
              </div>
              {selectedMagazine && (
                <MagazineForm
                  fieldValues={selectedMagazine}
                  handleMagazineForm={handleEditMagazine}
                  btn="Save"
                  magazines={magazines}
                  isLoading={editMagazineLoading}
                  setSearchText={setSearchText}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  );
};

export default EditMagazine;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import { getBrandFilter } from "../selectors";
import { addBrand, getBrandsList } from "../actions";
import { fileContext } from "../../../../../constants";

export const useAddBrand = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addBrandLoading, setAddBrandLoading] = useState(false);
  const dispatch = useDispatch();
  const filter = useSelector(getBrandFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const handleAddBrand = async (values) => {
    const payload = {
      brand: { name: values.brand_name, status: values.status },
      logo: files.find((file) => file.file_context === fileContext.BRAND_LOGO),
    };

    setAddBrandLoading(true);
    const is_success = await dispatch(addBrand(payload));
    setAddBrandLoading(false);
    if (is_success) {
      dispatch(getBrandsList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addBrandLoading,
    isOpenModal,
    toggleModal,
    handleAddBrand,
  };
};

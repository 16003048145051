import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import { addOffer, getOffersList } from "../actions";
import { getProductsList } from "../../product/actions";
import { getProducts } from "../../product/selectors";
import { productsListParams } from "../constant";
import moment from "moment";
import { getOffersFilter } from "../selectors";
import { getMarketPlacesList } from "../../marketplace/actions";
import { getMarketPlaces } from "../../marketplace/selectors";
export const useAddOffer = () => {
  const dispatch = useDispatch();
  const offerFilter = useSelector(getOffersFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);
  const products = useSelector(getProducts);
  const marketplaces = useSelector(getMarketPlaces);
  const [addOfferLoading, setAddOfferLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const getProductsData = async () => {
    setAddOfferLoading(true);
    const is_success = await dispatch(getProductsList(productsListParams));
    await dispatch(getMarketPlacesList(productsListParams));
    if (is_success) {
      toggleModal();
    }
    setAddOfferLoading(false);
  };

  const handleAddOffer = async (values) => {
    try {
      const payload = {
        offer: {
          title: values.title,
          sub_title: values.subTitle,
          details: values.details,
          note: values.note,
          type: values.type,
          code: values.code,
          redirection_link: values.redirectLink,
          validity: moment(values.validity).toDate().toString(),
          status: values.status,
          marketplace_id: values.marketplaceId.id,
          is_featured: values.is_featured,
          product_id: values.productId.id,
        },
        ...(files[0] && { banner: files[0] }),
      };
      setAddOfferLoading(true);
      const is_success = await dispatch(addOffer(payload));
      setAddOfferLoading(false);
      if (is_success) {
        dispatch(getOffersList(offerFilter));
        toggleModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addOfferLoading,
    isOpenModal,
    products,
    marketplaces,
    toggleModal,
    getProductsData,
    handleAddOffer,
  };
};

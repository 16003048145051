import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../../../utils/upload-file/actions";
import { getMarketplaceFilter } from "../selectors";
import { addMarketplace, getMarketPlacesList } from "../actions";
import { fileContext } from "../../../../../constants";

export const useAddMarketPlace = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addMarketplaceLoading, setAddMarketplaceLoading] = useState(false);
  const dispatch = useDispatch();
  const filter = useSelector(getMarketplaceFilter);
  const files = useSelector(({ fileReducer }) => fileReducer.files);

  const handleAddMarketplace = async (values) => {
    const payload = {
      marketplace: { name: values.name, status: values.status },
      logo: files.find((file) => file.file_context === fileContext.LOGO),
      banner: files.find((file) => file.file_context === fileContext.BANNER),
    };

    setAddMarketplaceLoading(true);
    const is_success = await dispatch(addMarketplace(payload));
    setAddMarketplaceLoading(false);
    if (is_success) {
      dispatch(getMarketPlacesList(filter));
      toggleModal();
    }
  };

  const toggleModal = () => {
    dispatch(clearFiles());
    setIsOpenModal(!isOpenModal);
  };

  return {
    addMarketplaceLoading,
    isOpenModal,
    toggleModal,
    handleAddMarketplace,
  };
};

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMarketPlaceCount,
  getMarketPlaces,
  getMarketplaceFilter,
} from "./selectors";
import {
  getMarketPlacesList,
  resetMarketplacesFilter,
  setMarketplacesFilter,
} from "./actions";

export const useMarketplaces = () => {
  const dispatch = useDispatch();
  const marketPlaces = useSelector(getMarketPlaces);
  const filters = useSelector(getMarketplaceFilter);
  const marketPlacesCount = useSelector(getMarketPlaceCount);

  const [loadingMarketPlaces, setLoadingMarketPlaces] = useState(false);

  useEffect(() => {
    const fetchMarketplaces = async () => {
      setLoadingMarketPlaces(true);
      await dispatch(getMarketPlacesList(filters));
      setLoadingMarketPlaces(false);
    };
    fetchMarketplaces();
  }, [filters, dispatch]);

  const setFilter = (key, value) => {
    dispatch(setMarketplacesFilter({ key, value }));
  };

  const resetFilter = () => {
    dispatch(resetMarketplacesFilter());
  };

  return {
    marketPlaces,
    loadingMarketPlaces,
    filters,
    marketPlacesCount,
    setFilter,
    resetFilter,
  };
};

import { api, apiEndPoints } from "../../../../api";
import { showSnackbar } from "../../../../components/snackbar/actions";
import { snackbarTypes } from "../../../../constants";

const namespace = "containers/offers/marketplace";

export const SET_MARKETPLACE_LIST = `${namespace}/SET_MARKETPLACE_LIST`;
export const SET_MARKETPLACE_FILTER = `${namespace}/SET_MARKETPLACE_FILTER`;
export const RESET_MARKETPLACE_FILTER = `${namespace}/RESET_MARKETPLACE_FILTER`;

const setMarketplacesList = (payload) => ({
  type: SET_MARKETPLACE_LIST,
  payload,
});

export const setMarketplacesFilter = (payload) => ({
  type: SET_MARKETPLACE_FILTER,
  payload,
});

export const resetMarketplacesFilter = (payload) => ({
  type: RESET_MARKETPLACE_FILTER,
  payload,
});

export const getMarketPlacesList = (params) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.marketplaces, {
      params: { params },
    });
    const { data = {} } = response;
    if (data?.marketplaces) {
      dispatch(setMarketplacesList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const addMarketplace = (payload) => async (dispatch) => {
  try {
    const response = await api.post(apiEndPoints.addMarketplace, payload);
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      showSnackbar({
        message: "Marketplace not added, Please try again!",
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    return false;
  }
};

export const getSingleMarketplace = (id) => async (dispatch) => {
  try {
    const response = await api.get(apiEndPoints.getSingleMarketPlace(id));
    const { data: { is_success, marketplace, logo, banner } = {} } = response;
    if (is_success) {
      return { marketplace, files: [logo, banner] };
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const editMarketPlace = (payload, id) => async (dispatch) => {
  try {
    const response = await api.put(
      apiEndPoints.getSingleMarketPlace(id),
      payload
    );
    const { data: { is_success, message } = {} } = response;
    if (is_success) {
      dispatch(
        showSnackbar({
          message: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

import React from "react";
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";

const DateTimePicker = ({
  selectedDate,
  handleChange,
  name,
  customInput,
  ...rest
}) => {
  return (
    <div className="date-picker">
      <DatePicker
        {...rest}
        name={name}     
        className="form__form-group-datepicker"
        selected={selectedDate}
        onChange={(date) => handleChange(date)}
        dateFormat="MM/dd/yyyy, h:mm aa"
        placeholderText="mm / dd / yyyy, --:--"
        customInput={customInput}
        timeIntervals={15}
        showTimeSelect
        autoComplete="off"
      />
    </div>
  );
};

DateTimePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  customInput: PropTypes.element,
};

export default DateTimePicker;
